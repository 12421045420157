import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UtilsService } from 'src/app/shared/services/utils.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { AuthenticateService } from 'src/app/shared/services/authenticate.service';
import { LoginModel } from 'src/app/shared/classes/LoginModel';
import { categories } from 'src/app/shared/classes/categories.model';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
	selector: 'molla-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
	@Input() containerClass = "container";
	categories!:categories[];
	loginModel!:LoginModel;
  

	constructor(
		private router: Router,
		public activeRoute: ActivatedRoute,
		public utilsService: UtilsService,
		public modalService: ModalService,
		private apiService: ApiService) {
	}

	ngOnInit(): void {
		this.loginModel = JSON.parse(sessionStorage.getItem('currentUser')!);
		this.caricaCategorie()
	}

	showLoginModal(event: Event): void {
		event.preventDefault();

		if (!this.loginModel) {
			this.modalService.showLoginModal();
		} else {
			this.router.navigate(['/shop/dashboard']);
		}
	}

	async caricaCategorie(){
		const fromDb = undefined;
		const params = fromDb || {};
		params.LIVELLO = 1;
		params.lingua = 'IT';

		this.categories = await this.apiService.fetchCategories(params);
	}
	
}
