<div [class]="'product-details-quantity ' + adClass">
    <div class="input-group input-spinner">
        <div class="input-group-prepend">
            <button style="min-width: 26px" class="btn btn-decrement btn-spinner" (click)="decrement()" type="button">
                <i class="icon-minus"></i>
            </button>
        </div>
        <input type="number" style="text-align: center" class="form-control" required placeholder="" min="1" [max]="max"
            [value]="current" (blur)="changeCurrent($event)">
        <div class="input-group-append">
            <button style="min-width: 26px" class="btn btn-increment btn-spinner" (click)="increment()" type="button">
                <i class="icon-plus"></i>
            </button>
        </div>
    </div>
</div>