import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { WSResponseModel } from '../classes/WSResponseModel';

declare var $: any;

@Injectable({
	providedIn: 'root'
})

export class UtilsService {

	isSticky = false;
	isFluidLayout = false;
	stickyHeight = 54;
	private password: string = '';

	constructor(
		@Inject(DOCUMENT) private document,
		private http: HttpClient,
		private toastrService: ToastrService) {
	}

	/**
	 * utils to detect safari browser
	 * @return {bool}
	 */
	isSafariBrowser(): boolean {
		let sUsrAg = navigator.userAgent;
		if (sUsrAg.indexOf('Safari') !== -1 && sUsrAg.indexOf('Chrome') === -1)
			return true;
		return false;
	}

	/**
	 * utils to detect Edge browser
	 * @return {bool}
	 */
	isEdgeBrowser(): boolean {
		let sUsrAg = navigator.userAgent;
		if (sUsrAg.indexOf("Edge") > -1)
			return true;
		return false;
	}

	/**
	 * utils to set header sticky
	 */
	setStickyHeader() {
		let outerHeight = $('.header').outerHeight();

		if (window.pageYOffset > outerHeight && window.innerWidth > 991 && $('.sticky-header')) {
			this.isSticky = true;
			this.stickyHeight = $('.sticky-header').outerHeight();
		} else {
			this.isSticky = false;
		}
	}

	/**
	 * Scrolling to Page content section
	 */
	scrollToPageContent(target = '.page-content') {

		//MP GESTIONE NULL PER ERRORE offsetTop
		let to = 0;
		if ((document.querySelector(target) as HTMLElement) != null) {
			to = (document.querySelector(target) as HTMLElement).offsetTop - 74;
		};

		if (this.isSafariBrowser() || this.isEdgeBrowser()) {
			let pos = window.pageYOffset;
			let timerId = setInterval(() => {
				if (pos <= to) clearInterval(timerId);
				else {
					window.scrollBy(0, -120);
					pos -= 120;
				}
			}, 1);
		} else {
			window.scrollTo({
				top: to,
				behavior: 'smooth'
			});
		}
	}

	/**
	 * Scroll Top Button
	 * @param e 
	 */
	scrollTop(e: Event) {
		if (this.isSafariBrowser() || this.isEdgeBrowser()) {
			let pos = window.pageYOffset;
			let timer_id = setInterval(() => {
				if (pos <= 0)
					clearInterval(timer_id);
				window.scrollBy(0, -120);
				pos -= 120;
			}, 1);
		} else {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		}
		e.preventDefault();
	}

	catFilter(products = [], category: any, flag = false) {
		if (category[0] === 'All') return products;

		return products.filter(item => {
			for (let i = 0; i < category.length; i++) {
				if (item.category.find(cat => cat.slug == category[i])) {
					if (!flag) return true;
				} else {
					if (flag) return false;
				}
			}

			if (flag)
				return true;
			else
				return false;
		})
	}

	attrFilter(products = [], attr: string) {
		return products.filter(item => {
			if (attr === 'all') {
				return true;
			}

			if (attr === 'sale' && item.sale_price) {
				return true;
			}

			if (attr === 'rated' && item.ratings > 3) {
				return true;
			}

			if (attr === 'until' && item.until) {
				return true;
			}

			return item[attr] === true;
		});
	}


	/* utils to set e get password */
	setPassword(password: string) {
		this.password = password;
	}

	getPassword(): string {
		return this.password;
	}


	shareOnLinkedIn(url, title, description, imageUrl) {
		// Codifica dei parametri per l'URL
		let encodedUrl = encodeURIComponent(window.location.href + '/' + url);
		let encodedTitle = encodeURIComponent(title);
		let encodedDescription = encodeURIComponent(description);
		let encodedImageUrl = encodeURIComponent(imageUrl);

		// URL di condivisione di Facebook
		//let linkedinShareUrl = `https://www.linkedin.com/sharer.php?u=${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}${encodedImageUrl !== '' ? `&picture=${encodedImageUrl}` : ''}`;
		let linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`

		// Apertura della finestra di condivisione di Facebook
		window.open(linkedinShareUrl, '_blank');
	}

	shareOnFacebook(url, title, description, imageUrl) {
		// Codifica dei parametri per l'URL
		let encodedUrl = encodeURIComponent(window.location.href + '/' + url);
		let encodedTitle = encodeURIComponent(title);
		let encodedDescription = encodeURIComponent(description);
		let encodedImageUrl = encodeURIComponent(imageUrl);

		// URL di condivisione di Facebook
		let facebookShareUrl = `https://www.facebook.com/sharer.php?u=${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}${encodedImageUrl !== '' ? `&picture=${encodedImageUrl}` : ''}`;

		// Apertura della finestra di condivisione di Facebook
		window.open(facebookShareUrl, '_blank');
	}

	shareOnInstagram(url, title, description, imageUrl) {
		// Codifica dei parametri per l'URL
		let encodedUrl = encodeURIComponent(window.location.href + '/' + url);
		let encodedTitle = encodeURIComponent(title);
		let encodedDescription = encodeURIComponent(description);
		let encodedImageUrl = encodeURIComponent(imageUrl);

		// URL di condivisione di Facebook
		//let instagramShareUrl = `https://www.instagram.com/sharer.php?u=${encodedUrl}&title=${encodedTitle}&description=${encodedDescription}${encodedImageUrl !== '' ? `&picture=${encodedImageUrl}` : ''}`;
		//instagram non permette la condivisione usando solo foto e video

		let instagramShareUrl = `https://www.instagram.com/`

		// Apertura della finestra di condivisione di Facebook
		window.open(instagramShareUrl, '_blank');
	}


	shareOnX(url, title, description, imageUrl) {
		// Codifica dei parametri per l'URL
		let encodedUrl = encodeURIComponent(window.location.href + '/' + url);
		let encodedTitle = encodeURIComponent(title);
		let encodedDescription = encodeURIComponent(description);
		let encodedImageUrl = encodeURIComponent(imageUrl);

		// URL di condivisione di X ex Twitter
		let xShareUrl = `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`;


		// Apertura della finestra di condivisione di X ex Twitter
		window.open(xShareUrl, '_blank');
	}


	async sendEmailAdmin(oggettoMail: string, testoEmail: string) {
		const body = {
			OggettoMail: oggettoMail,
			TestoEmail: testoEmail,
		};

		return await firstValueFrom(this.http.post<WSResponseModel>(`${environment.backend.baseURL}/Generale/sendEmailAdmin`, body))
			.then(result => {
				return JSON.parse(result.jsonlist);
			})
			.catch(
				error => {
					console.log('error: ' + error.toString())
					if (error.status) {
						switch (error.status) {
							case 400: {
								this.toastrService.error(error.error.info);
								break;
							}
							default: {
								this.toastrService.error(error.message);
							}
						}
					} else {
						this.toastrService.error(error.message);
					}
				}
			);
	}



	async logAction(testoLog: string) {
		const body = `"${testoLog}"`;  // Aggiunto il wrapping del testo tra doppi apici

		return await firstValueFrom(this.http.post(
			`${environment.backend.baseURL}/Generale/logAction`,body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json' })}))
			.then(result => {
				return result;
			})
			.catch(
				error => {
					 console.log('error: ' + error.toString())
					if (error.status) {
						switch (error.status) {
							case 400: {
								this.toastrService.error(error.error.info);
								break;
							}
							default: {
								this.toastrService.error(error.message);
							}
						}
					} else {
						this.toastrService.error(error.message);
					}
				}
			);
	}

}