// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CartHeaderState, CartItemState } from '../reducers/cart.reducer';
import { CompareState } from '../reducers/compare.reducer';
import { WishlistState } from '../reducers/wishlist.reducer';

export const getCartHeaderState = createFeatureSelector<CartHeaderState>('cart');
export const getCartItemsState = createFeatureSelector<CartItemState>('cart');
export const getCompareState = createFeatureSelector<CompareState>('compare');
export const getWishlistState = createFeatureSelector<WishlistState>('wishlist');


/************************    CartHeader Selectors   ***********************/
export const cartHeaderSelector = createSelector(
    getCartHeaderState, cartHeaderState => {
        return {
            custom_id: cartHeaderState.header?.custom_id,
            idUtente: cartHeaderState.header?.idUtente,
            shipping: cartHeaderState.header?.shipping,
            payment: cartHeaderState.header?.payment,
            anaCF: cartHeaderState.header?.anaCF,
            note: cartHeaderState.header?.note,
        };
    }
);



/************************    CartItem Selectors   ***********************/
export const cartItemsSelector = createSelector(
    getCartItemsState, cartItemState => {
        return cartItemState.data;
    }
);

//tentativo di aggiornare gli item del carrello nel selettore // funzionamento parziale
// export const cartItemsSelector = createSelector(
//     getCartItemsState,
//     (cartItemState, apiService: ApiService) => {
//         const cartItems: CartItem[] = cartItemState.data; // Dichiarare esplicitamente il tipo di cartItems

//         // Controllo preliminare per assicurarsi che cartItems non sia undefined o null
//         if (!cartItems) {
//             return [];
//         }

//         // Nel tuo componente o servizio
//         const updatedCartItems: CartItem[] = [];
//         for (const item of cartItems) {
//             apiService.getSingleProduct(item.id, false, item.qty).subscribe(result => {
//                 if (result != null) {
//                     const newItem = { ...item };
//                     newItem.price = result.product.price;
//                     updatedCartItems.push(newItem);
//                 }
//             });
//         }

//         // Dopo aver aggiornato tutti gli oggetti CartItem, puoi utilizzare il nuovo array aggiornato
//         // Ad esempio, assegnalo a una variabile o passalo a un altro metodo o componente
//         return updatedCartItems;
//     }
// );

/************************    Wishlist Selectors   ***********************/
export const wishlistSelector = createSelector(
    getWishlistState, wishlistState => {
        return wishlistState.data;
    }
);

/************************    Compare Selectors   ***********************/
export const compareSelector = createSelector(
    getCompareState, compareState => {
        return compareState.data;
    }
);