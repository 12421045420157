<main class="main">
    <molla-page-header [title]="'pwreset.pwreset' | translate" [subtitle]="'dashboard.myaccount' | translate"></molla-page-header>
    <nav class="breadcrumb-nav border-0 mb-0">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/">Home</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="javascript:;">Pages</a>
                </li>
                <li class="breadcrumb-item active">{{ 'pwreset.pwreset' | translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content pb-0">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h2 class="title mb-1">{{"pwreset.pwreset" | translate}}</h2>
                    <p class="mb-3" [innerHTML]="'pwreset.infoText' | translate"></p>


                    <form [formGroup]="pwForm" (submit)="pwReset()">

                        <input type="text" class="form-control" formControlName="username"  style="max-width: 300px;"
                            [placeholder]="'fld.username' | translate">
                        <div *ngIf="submitted && ((pwForm.get('username').hasError('required')) || (pwForm.get('username').hasError('pattern') && !pwForm.get('username').hasError('required')))"
                            class="required">
                            {{ "ctrl.username" | translate }}
                        </div>


                        <div class="form-footer">
                            <button type="submit" class="btn btn-outline-primary-2">
                                <span>{{ 'btn.continue' | translate | uppercase}}</span>
                                <i class="icon-long-arrow-right"></i>
                            </button>
                        </div>
                    </form>

                    <p><br></p>
                </div>
            </div>
        </div>
    </div>
</main>