<div class="mobile-menu-container mobile-menu-light">
    <div class="mobile-menu-wrapper">
        <span class="mobile-menu-close" (click)="hideMobileMenu()"><i class="icon-close"></i></span>

        <form action="#" method="get" class="mobile-search" (submit)="submitSearchForm($event)">
            <label for="mobile-search" class="sr-only">Search</label>
            <input type="search" class="form-control" name="mobile-search" id="mobile-search" placeholder="Search in..."
                required>
            <button class="btn btn-primary" type="submit"><i class="icon-search"></i></button>
        </form>

        <ul ngbNav #nav="ngbNav" class="nav nav-pills-mobile nav-border-anim">
            <li ngbNavItem>
                <a ngbNavLink>
                    Menu
                </a>
                <ng-template ngbNavContent>
                    <nav class="mobile-nav">
                        <ul class="mobile-menu">                            
                            <li>
                                <a routerLink="/">Home</a>
                            </li>
                    
                            <li>
                                <a routerLink="/pages/about">MyLiteLED</a>
                            </li>
                            
                            <li>
                                <a routerLink="/">
                                    Case Study
                                    <span class="mmenu-btn" (click)="submenuToggle($event)"></span>
                                </a>
                                <ul>
                                    <li><a href="\pages\case-study\un-insegna-innovativa-ed-ecosostenibile" rel="noopener">
                                        UN'INSEGNA INNOVATIVA<br>
                                        ed ecosostenibile</a>
                                    </li>
                                    <li><a href="\pages\case-study\una-grande-insegna-da-progettare-nel-dettaglio" rel="noopener">
                                        UNA GRANDE INSEGNA<br>
                                        da progettare nel dettaglio</a>
                                    </li>
                                </ul>
                            </li>

                            <li routerLink="/pages/contact">
                                <a routerLink="/pages/contact">Contatti</a>
                            </li>
                    
                            <li routerLink="/shop/sidebar/list">
                                <a routerLink="/shop/sidebar/list">Shop</a>
                            </li>

                            <li>
                                <a routerLink="/">
                                    Cataloghi
                                    <span class="mmenu-btn" (click)="submenuToggle($event)"></span>
                                </a>
                                <ul>
                                    <li>
                                        <a routerLink="/pages/cataloghi-prodotti/catalogo">CATALOGO</a>
                                    </li>
                                    <li>
                                        <a routerLink="/pages/cataloghi-prodotti/flex-neonled">FLEX NEONLED SIGN</a>
                                    </li>
                                    <li>
                                        <a routerLink="/pages/cataloghi-prodotti/led-wall">LED WALL</a>
                                    </li>
                                    <li>
                                        <a routerLink="/pages/cataloghi-prodotti/-flex">PROFILI M-FLEX</a>
                                    </li>  
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>
                    {{ "str.categories" | translate }}
                </a>
                <ng-template ngbNavContent>
                    <nav class="mobile-cats-nav">
                        <ul class="mobile-cats-menu" *ngFor="let item of categories;">
                            <li class="item-cats-lead"><a routerLink="/shop/sidebar/3cols"
                                [queryParams]="{category: item.CategoriaId}">{{ item.Descrizione }}</a></li>
                            
                        </ul>
                    </nav>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>

        <div class="social-icons">
            <a href="https://it.linkedin.com/company/myliteled" target="_blank" class="social-icon"
                    title="Linkedin"><i class="icon-linkedin"></i></a>
                <a href="https://www.facebook.com/myliteled/" target="_blank" class="social-icon"
                    title="Facebook"><i class="icon-facebook-f"></i></a>
                <a href="https://www.instagram.com/myliteled" target="_blank" class="social-icon"
                    title="Instagram"><i class="icon-instagram"></i></a>
                <a href="https://www.youtube.com/@myliteledscritteluminose" target="_blank"
                    class="social-icon" title="Youtube"><i class="icon-youtube"></i></a>
                <a href="https://twitter.com/myliteled" target="_blank" class="social-icon"
                    title="Twitter"><i class="icon-x-twitter"></i></a>
        </div>
    </div>
</div>