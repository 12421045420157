import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { LoginModel } from '../../classes/LoginModel';
import { ActivatedRoute } from '@angular/router';
import { AnaService } from 'src/app/shared/services/ana.service';
import { TranslateService } from '@ngx-translate/core';
import { emailRegex, pivaRegex, telRegex } from 'src/app/core/constants/constants';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { FormService } from 'src/app/shared/services/form.service';
import { province } from 'src/app/shared/classes/province.model';
import { AnaUser } from 'src/app/shared/classes/ana-user.model';


@Component({
	selector: 'molla-manage-user-data',
	templateUrl: './manage-user-data.component.html',
	styleUrls: ['./manage-user-data.component.scss']
})
export class ManageUserDataComponent implements OnInit {
	loginModel!: LoginModel;
	regForm!: FormGroup;
	datiUtente: AnaUser;
	cities: province[] = [];
	submitted: boolean = false;

	@Input() newUser: boolean = true;
	@Input() backpage: any;

	constructor(
		private formBuilder: FormBuilder,
		private anaService: AnaService,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private utilsService: UtilsService,
		private apiService: ApiService,
		private toastrService: ToastrService,
		private formService: FormService) {
			
			//Carico i controlli della form
			this.regForm = this.formBuilder.group({
				id: [0],
				idAzienda: [environment.backend.IDDitta],
				tipo: ['C'],
				name: ['', Validators.required],
				lastname: ['', Validators.required],
				privateCli: [false],
				company: ['', Validators.required],
				vat: ['', { validators: [Validators.required, Validators.pattern(pivaRegex)] }],
				taxid: ['', Validators.required],
				address: ['', Validators.required],
				town: ['', Validators.required],
				city: ['', [Validators.required, this.validateSelectedOption]],
				pcode: ['', Validators.required],
				state: [''],
				country: ['IT'],
				phone: ['', { validators: [Validators.required, Validators.pattern(telRegex)] }],
				email: ['', { validators: [Validators.required, Validators.pattern(emailRegex)] }],
				password: ['', Validators.required],
				privacy1: [null, Validators.requiredTrue],
				privacy2: [null, Validators.requiredTrue]
			});
		}


	get r() { return this.regForm.controls; }

	ngOnInit(): void {

		this.loginModel = JSON.parse(sessionStorage.getItem('currentUser')!);

		

		//Carico le provincie
		this.getCities();


		if (this.loginModel && this.loginModel.token) {
			//this.router.navigateByUrl(`/shop/dashboard`);
			this.showDatiUtente()
		}

		// Subscribe ai queryParams e imposta i valori dei controlli
		this.route.queryParams.subscribe(params => {
			if (params['email'] && this.regForm) {
				this.r['email'].setValue(params['email']);
			}

			if (this.utilsService.getPassword() && this.regForm) {
				this.r['password'].setValue(this.utilsService.getPassword());
			}

			if (this.regForm && this.r['email'].value !== "" && this.r['password'].value !== "") {
				//this.r['privacy1'].setValue(true);
				//this.r['privacy2'].setValue(true);
				this.utilsService.setPassword("");
			}
		});

	}

	async getCities() {
		this.cities = await this.apiService.fetchCities()
	}

	registerPrivate_changeEvent() {
		// Qui puoi ottenere lo stato del checkbox e reagire ai cambiamenti
		const isChecked = this.r['privateCli']?.value;
		//console.log('Stato del checkbox:', isChecked);

		// Puoi anche eseguire azioni in base allo stato del checkbox
		if (isChecked) {
			this.r['company']?.disable();
			this.r['company']?.setValue("");
			this.r['vat']?.disable();
			this.r['vat']?.setValue("");
		} else {
			this.r['company']?.enable();
			this.r['vat']?.enable();
		}
	}

	async checkVat() {
		const vatValue = this.r['vat']?.value;

		let isVal = await this.anaService.CheckVatUser(vatValue);

		if (isVal != 0) {
			this.r['vat']?.setErrors({ vatExist: true }); // Set a custom validation error on _regVat control
			this.toastrService.error(this.translate.instant('ctrl.vatExist'))
		} else {
			this.r['vat']?.setErrors(null); // Clear any existing custom validation errors on _regVat contro
		}

		// Mark the form as invalid or valid based on the _regVat control's validity
		this.regForm.updateValueAndValidity();
	}

	// Funzione di validazione personalizzata
	validateSelectedOption(control: AbstractControl): { [key: string]: any } | null {
		const selectedValue = control.value;
		if (selectedValue !== null && selectedValue !== "") {
			return null; // Valido, nessun errore
		} else {
			return { 'invalidOption': true }; // Non valido, restituisce un errore
		}
	}

	regCity_changeEvent() {
		// Ottieni il valore selezionato dal form control _regCity
		const selectedCityValue = this.r['city']?.value;

		// Cerca l'oggetto SelectPROV corrispondente al valore selezionato
		const selectedCity: province | undefined = this.cities.find(city => city.PROV === selectedCityValue);

		if (selectedCity) {
			// Se l'oggetto è stato trovato, imposta il valore della proprietà REGIONE nel form control _regState
			this.r['state'].setValue(selectedCity.REGIONE);
		}
	}

	async showDatiUtente() {
		this.datiUtente = await this.anaService.fetchDatiUtente(this.loginModel.idUtente)

		if (this.datiUtente) {
			//console.log(this.datiUtente)				
			this.r['id'].setValue(this.datiUtente.id);
			this.r['email'].setValue(this.datiUtente.email);
			//this.r['password'].setValue(this.datiUtente.password); //la password è criptata e non si può decriptare // anche per sicurezza è meglio lasciarla reinserire
			this.r['name'].setValue(this.datiUtente.name);
			this.r['lastname'].setValue(this.datiUtente.lastname);
			this.r['privateCli'].setValue(this.datiUtente.privateCli);
			this.r['company'].setValue(this.datiUtente.company);
			this.r['vat'].setValue(this.datiUtente.vat);			
			this.registerPrivate_changeEvent() //se privateCli true svuota e blocca company e vat
			this.r['taxid'].setValue(this.datiUtente.taxid);
			this.r['address'].setValue(this.datiUtente.address.address);
			this.r['town'].setValue(this.datiUtente.address.town);
			this.r['city'].setValue(this.datiUtente.address.city);
			this.r['pcode'].setValue(this.datiUtente.address.pcode);
			this.r['state'].setValue(this.datiUtente.address.state);
			this.r['country'].setValue(this.datiUtente.address.country);
			this.r['phone'].setValue(this.datiUtente.phone);
			this.r['privacy1'].setValue(this.datiUtente.privacy1);
			this.r['privacy2'].setValue(this.datiUtente.privacy2);

			// Now that data is available, you can access properties
			if (!this.datiUtente) {
				this.toastrService.error(this.translate.instant('msg.ko'))
			}
		}
	}

	async register() {

		// Validazione dei campi del modulo
		if (!this.formService.validateForm(this.regForm)) {
			this.submitted = true;
			return; // Esci se la validazione fallisce
		}

		const regAna: AnaUser = {
			idAzienda: this.r['idAzienda'].value,
			id: this.datiUtente && this.datiUtente.id ? this.datiUtente.id : 0,
			userid: this.datiUtente && this.datiUtente.userid ? this.datiUtente.userid : this.r['email'].value, //lo userid non può cambiare
			password: this.r['password'].value,
			privacy1: this.r['privacy1'].value,
			privacy2: this.r['privacy2'].value,
			tipo: this.r['tipo'].value,
			name: this.r['name'].value,
			lastname: this.r['lastname'].value,
			privateCli: this.r['privateCli'].value,
			company: this.r['company'].value,
			vat: this.r['vat'].value,
			taxid: this.r['taxid'].value,
			email: this.r['email'].value,
			phone: this.r['phone'].value,
			address: {
				name: this.r['name'].value,
				address: this.r['address'].value,
				town: this.r['town'].value,
				city: this.r['city'].value.substring(0, 5),
				pcode: this.r['pcode'].value,
				state: this.r['state'].value,
				country: this.r['country'].value,
				phone: this.r['phone'].value,
			},
		};

		this.loginModel = await this.anaService.sendFormRegistrazione(regAna, this.newUser)

		// if (this.loginModel.token) {
		// 	if (this.backpage) {
		// 		window.location.href = this.backpage;
		// 	} else {
		// 		window.location.reload();
		// 	}
		// }
	}

}
