import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';
import { WSResponseModel } from '../classes/WSResponseModel';
import { categories } from '../classes/categories.model';
import { CondCF } from '../classes/cond-cf.model';
import { ToastrService } from 'ngx-toastr';
import { prodFiltri } from '../classes/prod-filtri.model';
import { NumberInput } from '@angular/cdk/coercion';


@Injectable({
	providedIn: 'root'
})


export class ApiService {
	condCF!: CondCF

	constructor(
		private http: HttpClient,
		private toastrService: ToastrService) {
	}

	/**
	 * Get Products
	 */
	public fetchBlogData(params: any, initial = 'blogs/classic', perPage: number,): Observable<any> {
		let temp = initial;
		if (!initial.includes('?')) {
			temp += '?';
		}

		for (let key in params) {
			temp += key + '=' + params[key] + '&';
		}

		if (!params.page) {
			temp += 'page=1';
		}

		if (!params.perPage) {
			temp += '&perPage=' + perPage;
		}

		return this.http.get(`${environment.backend.baseURL}/${temp}`);
	}

	/**
	 * Get Products
	 */
	public fetchSinglePost(slug: string): Observable<any> {
		return this.http.get(`${environment.backend.baseURL}/${'single/' + slug + '?demo=' + environment.demo}`);
	}

	/**
	 * Get Products for home page
	 */
	public fetchHomeData(category: string = ''): Observable<any> {

		let temp = 'EC/ArticoliGet/ProductsData?';

		let httpParams = new HttpParams();
		httpParams = httpParams.set('perPage', 20);
		httpParams = httpParams.set('category', category);
		httpParams = httpParams.set('OrdDir', 'random');


		this.condCF = JSON.parse(sessionStorage.getItem('currentCondCF')!)
		let headers = new HttpHeaders(); // Crea un oggetto headers vuoto  
		if (this.condCF) {
			headers = headers.append('CodCliente', this.condCF?.CodCliente);
			headers = headers.append('Privato', this.condCF?.Privato.toString());
			// Trasformare gli array in una stringa separata da virgole
			const codListinoString = this.condCF?.CodListino.join(',');
			const codScontoString = this.condCF?.CodSconto.join(',');
			headers = headers.append('CodListino', codListinoString);
			headers = headers.append('CodSconto', codScontoString);
			headers = headers.append('CodPag', this.condCF?.CodPag);
		}

		return this.http.get(`${environment.backend.baseURL}/${temp}`, { headers, params: httpParams });
	}



	/**
	 * Get Element Products
	 */
	public fetchElementData(): Observable<any> {
		return this.http.get(`${environment.backend.baseURL}/elements/products`);
	}

	/**
	 * Get Element Blog
	 */
	public fetchElementBlog(): Observable<any> {
		return this.http.get(`${environment.backend.baseURL}/elements/blogs`);
	}


	//PD
	/**
	 * Get products
	 */
	public getSingleProduct(CodArt: string, isQuickView = false, Qta: number = 0): Observable<any> {
		//return this.http.get(`${environment.backend.baseURL/products/${slug}?demo=${environment.demo}&isQuickView=${isQuickView}`);


		let temp = 'EC/ArticoliGet/ProductData?';

		if (CodArt) {
			temp += 'CodArt=' + CodArt;
		}
		if (isQuickView) {
			temp += '&isQuickView=' + isQuickView;
		}
		if (Qta) {
			temp += '&Qta=' + Qta;
		}

		this.condCF = JSON.parse(sessionStorage.getItem('currentCondCF')!)
		let headers = new HttpHeaders(); // Crea un oggetto headers vuoto  
		if (this.condCF) {
			headers = headers.append('CodCliente', this.condCF?.CodCliente);
			headers = headers.append('Privato', this.condCF?.Privato.toString());
			// Trasformare gli array in una stringa separata da virgole
			const codListinoString = this.condCF?.CodListino.join(',');
			const codScontoString = this.condCF?.CodSconto.join(',');
			headers = headers.append('CodListino', codListinoString);
			headers = headers.append('CodSconto', codScontoString);
			headers = headers.append('CodPag', this.condCF?.CodPag);
		}

		return this.http.get(`${environment.backend.baseURL}/${temp}`, { headers });
	}
	
	async fetchProductDesc(CodArt: string) {
		let temp = 'EC/ArticoliGet/ProductDesc?';

		if (CodArt) {
			temp += 'CodArt=' + CodArt;
		}

		return await firstValueFrom(this.http.get(`${environment.backend.baseURL}/${temp}`))
			.then((result: any) => {
				if (result && result.descrizione) {
					return result.descrizione;
				} else {
					return CodArt;
				}
			})
			.catch(
				error => {
					console.log('error: ' + error.toString())
					if (error.status) {
						switch (error.status) {
							case 400: {
								this.toastrService.error(error.error.info);
								break;
							}
							default: {
								this.toastrService.error(error.message);
							}
						}
					} else {
						this.toastrService.error(error.message);
					}
				}
			);
	}


	

	async fetchProductAddProp(CodArt: string, CodCliente: string) {
		let temp = 'EC/ArticoliGet/ProductAddProp?';

		if (CodArt) {
			temp += 'CodArt=' + CodArt;
		}
		if (CodArt) {
			temp += '&CodCliente=' + CodCliente;
		}

		return await firstValueFrom(this.http.get(`${environment.backend.baseURL}/${temp}`))
			.then((result: any) => {
				return result;
			})
			.catch(
				error => {
					console.log('error: ' + error.toString())
					if (error.status) {
						switch (error.status) {
							case 400: {
								this.toastrService.error(error.error.info);
								break;
							}
							default: {
								this.toastrService.error(error.message);
							}
						}
					} else {
						this.toastrService.error(error.message);
					}
				}
			);
	}



	/**
	 * Get Products
	 */
	public fetchShopData(params: any, perPage: number, initial = 'EC/ArticoliGet/ProductsData'): Observable<any> {
		let temp = initial;
		if (!initial.includes('?')) {
			temp += '?';
		}

		let httpParams: HttpParams = new HttpParams();

		let searchTerm: string = "";
		let tags: string = "";
		let isTop: boolean = false;
		let isNew = false;
		let isFeatured = false;
		let isPromo = false;

		for (let key in params) {
			switch (key) {
				case "searchTerm":
					// Esempio: Filtri[searchTerm]=valore
					searchTerm = params[key];
					break;
				case "tags":
					tags = params[key]
					break;
				case "isTop":
					isTop = params[key]
					break;
				case "isNew":
					isNew = params[key]
					break;
				case "isFeatured":
					isFeatured = params[key]
					break;
				case "isPromo":
					isPromo = params[key]
					break;
				default:
					httpParams = httpParams.set(key, params[key]);
			}
		}

		const prodFiltriInstance = new prodFiltri(searchTerm, tags, false, false, false, false);
		httpParams = httpParams.set('searchTerm', prodFiltriInstance.searchTerm);
		httpParams = httpParams.set('tags', prodFiltriInstance.tags);
		httpParams = httpParams.set('isTop', prodFiltriInstance.isTop);
		httpParams = httpParams.set('isNew', prodFiltriInstance.isNew);
		httpParams = httpParams.set('isFeatured', prodFiltriInstance.isFeatured);
		httpParams = httpParams.set('isPromo', prodFiltriInstance.isPromo);

		if (!params.page) {
			httpParams = httpParams.set('page', '1');
		}

		if (!params.perPage) {
			httpParams = httpParams.set('perPage', perPage.toString());
		}

		this.condCF = JSON.parse(sessionStorage.getItem('currentCondCF')!);
		let headers = new HttpHeaders();
		if (this.condCF) {
			headers = headers.append('CodCliente', this.condCF?.CodCliente);
			headers = headers.append('Privato', this.condCF?.Privato.toString());


			const codListinoString = this.condCF?.CodListino.join(',');
			const codScontoString = this.condCF?.CodSconto.join(',');
			// const codListinoString = this.condCF?.CodListino
			// 	.filter(str => str.trim() !== '') // Rimuove le stringhe vuote dopo lo strip
			// 	.join(',');
			// const codScontoString = this.condCF?.CodSconto
			// 	.filter(str => str.trim() !== '') // Rimuove le stringhe vuote dopo lo strip
			// 	.join(',');
			if (codListinoString.trim() !== '') {
				headers = headers.append('CodListino', codListinoString);				
			}
			if (codScontoString.trim() !== '') {
				headers = headers.append('CodSconto', codScontoString);
			}
			if (this.condCF?.CodPag.trim() !== '') {
				headers = headers.append('CodPag', this.condCF?.CodPag);
			}
		}


		return this.http.get(`${environment.backend.baseURL}/${temp}`, { headers, params: httpParams });
	}


	/**
	 * Get Categories
	 */
	async fetchCategories(params: any, initial = 'EC/ArticoliGet/CategoriesData') {

		let temp = initial;
		if (!initial.includes('?')) {
			temp += '?';
		}
		for (let key in params) {
			temp += key + '=' + params[key] + '&';
		}

		return await firstValueFrom(this.http.get<WSResponseModel>(`${environment.backend.baseURL}/${temp}`))
			.then(result => {
				return JSON.parse(result!.jsonlist)
			})
			.catch(
				error => {
					console.log('error: ' + error.toString())
					if (error.status) {
						switch (error.status) {
							case 400: {
								this.toastrService.error(error.error.info);
								break;
							}
							default: {
								this.toastrService.error(error.message);
							}
						}
					} else {
						this.toastrService.error(error.message);
					}
				}
			);
	}


	async fetchCategory(params: any, initial = 'EC/ArticoliGet/CategoryData') {

		let temp = initial;
		if (!initial.includes('?')) {
			temp += '?';
		}
		for (let key in params) {
			temp += key + '=' + params[key] + '&';
		}

		return await firstValueFrom(this.http.get<any>(`${environment.backend.baseURL}/${temp}`))
			.then(result => {
				return result!.categoria
			})
			.catch(
				error => {
					console.log('error: ' + error.toString())
					if (error.status) {
						switch (error.status) {
							case 400: {
								this.toastrService.error(error.error.info);
								break;
							}
							default: {
								this.toastrService.error(error.message);
							}
						}
					} else {
						this.toastrService.error(error.message);
					}
				}
			);
	}


	public fetchNItemxCategory(CategoriaId: string): Observable<any> {

		let temp = "EC/ArticoliGet/CountItemCat?";
		temp += 'CategoriaId=' + CategoriaId;

		return this.http.get(`${environment.backend.baseURL}/${temp}`);
	}



	public fetchAttachment(EVENTO_ALLEGATO_ID): Observable<any> {
		let temp = 'EC/ArticoliGet/GetAttachment?';
		if (EVENTO_ALLEGATO_ID > 0) {
			temp += "id=" + EVENTO_ALLEGATO_ID.toString()
		}
		return this.http.get(`${environment.backend.baseURL}/${temp}`);
	}

	/**
	* Get Tags
	*/
	async fetchTagsPerTipo(tipo: number, initial = 'EC/ArticoliGet/ListTagsPerTipo') {

		let temp = initial;
		if (!initial.includes('?')) {
			temp += '?';
		}
		if (tipo > 0) {
			temp += "Tipo=" + tipo.toString()
		}
		return await firstValueFrom(this.http.get<WSResponseModel>(`${environment.backend.baseURL}/${temp}`))
			.then(result => {
				return JSON.parse(result!.jsonlist)
			})
			.catch(
				error => {
					console.log('error: ' + error.toString())
					if (error.status) {
						switch (error.status) {
							case 400: {
								this.toastrService.error(error.error.info);
								break;
							}
							default: {
								this.toastrService.error(error.message);
							}
						}
					} else {
						this.toastrService.error(error.message);
					}
				}
			);
	}

	async fetchTagsPerArticolo(tipo: number, codart: string, initial = 'EC/ArticoliGet/ListTagsPerArt') {

		let temp = initial;
		if (!initial.includes('?')) {
			temp += '?';
		}
		if (codart != "") {
			temp += "CodArt=" + codart
		}
		if (tipo > 0) {
			temp += "&Tipo=" + tipo.toString()
		}
		return await firstValueFrom(this.http.get<WSResponseModel>(`${environment.backend.baseURL}/${temp}`))
			.then(result => {
				return JSON.parse(result!.jsonlist)
			})
			.catch(
				error => {
					console.log('error: ' + error.toString())
					if (error.status) {
						switch (error.status) {
							case 400: {
								this.toastrService.error(error.error.info);
								break;
							}
							default: {
								this.toastrService.error(error.message);
							}
						}
					} else {
						this.toastrService.error(error.message);
					}
				}
			);
	}


	/**
	* Get province / regioni
	*/
	async fetchCities(initial = 'EC/TabelleGet/ListPROV') {

		let temp = initial;
		/* 		if (!initial.includes('?')) {
					temp += '?';
				}
		 */
		return await firstValueFrom(this.http.get<WSResponseModel>(`${environment.backend.baseURL}/${temp}`))
			.then(result => {
				let prov = JSON.parse(result!.jsonlist)

				prov = prov.map(item => {
					return {
						PROV: item.PROV1,
						DES_PROV: item.DES_PROV,
						REGIONE: item.REGIONE
					};
				});

				return prov;
			})
			.catch(
				error => {
					console.log('error: ' + error.toString())
					if (error.status) {
						switch (error.status) {
							case 400: {
								this.toastrService.error(error.error.info);
								break;
							}
							default: {
								this.toastrService.error(error.message);
							}
						}
					} else {
						this.toastrService.error(error.message);
					}
				}
			);
	}

	//recupera le condizioni di pagamento
	//se COD_CF è valorizzato filtra solo quelle associate al cliente
	async fetchCONDPA(COD_CF: string) {
		const httpOptions = {
			headers: {
				COD_CF: COD_CF
			}
		};

		const endpoint = 'AnagraficheGet/ListConPaCF';
		const url = `${environment.backend.baseURL}/${endpoint}`;


		return await firstValueFrom(this.http.get<WSResponseModel>(url, httpOptions))
			.then(
				result => {
					let conpa = JSON.parse(result!.jsonlist)

					conpa = conpa.map(item => {
						return {
							COD_PAGA: item.COD_PAGA,
							DES_PAGA: item.DES_PAGA,
							DES_TIPO_CONDPA: item.DES_TIPO_CONDPA
						};
					});

					return conpa;
				}
			)
			.catch(
				error => {
					if (error.status) {
						switch (error.status) {
							case 401: {

								//this.router.navigate(['login']);
								sessionStorage.clear();
								this.toastrService.error(error.message);
								break;
							}
							case 405: {
								//this.router.navigate(['home']);

								this.toastrService.error(error.message);
								break;
							}
							default: {
								if (error.error.info)
									//this.toastrService.error(this.response.info);

									this.toastrService.error(error.message);
							}
						}

					} else {
						this.toastrService.error(error.message);
					}

				}
			);

	}
}