<div class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
        <div class="container">
            <ol class="breadcrumb" itemscope itemtype="https://schema.org/BreadcrumbList">
                <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                    <a routerLink="/" itemprop="item">Home</a>
                    <meta itemprop="position" content="1" />
                </li>
                <li class="breadcrumb-item active" itemprop="itemListElement" itemscope
                    itemtype="https://schema.org/ListItem">
                    <a itemprop="item">Lightbox Revolution</a>
                    <meta itemprop="position" content="2" />
                </li>
            </ol>
        </div>
    </nav>

    <div class="container">
        <div class="page-header page-header-big text-center"
            style="background-image: url('assets/images/lightbox-revolution/mll-astronauta-web-30d81d59.jpeg')">
            <h1 class="page-title text-white">Lightbox Revolution<br>
                <span class="text-white">Metti in movimento la tua comunicazione</span>
            </h1>
        </div>
    </div>

    <div class="page-content pb-0">
        <div class="container">
            <div class="row">

                <div class="col-md-8">
                    <p>
                        Lightbox Revolution è un sistema di schede LED che, posto all’interno di lightbox, permette di
                        realizzare cassonetti monofacciali luminosi caratterizzati da una innovativa retroilluminazione
                        animata. Le animazioni, realizzate attraverso appositi programmi, consentono di mettere in
                        risalto aree specifiche della grafica stampata sul tessuto e generare diversi effetti visivi.
                    </p>
                    <p>
                        I Lightbox Revolution sono a luce bianca (no RGB) e sono consigliati per l’utilizzo in ambienti
                        interni.
                    </p>
                    <h2 class="title-sm mt-4">
                        Com’è fatto<br>
                    </h2>
                    <p>
                        Il sistema di retroilluminazione dinamica a LED è composto da</p><br>

                    <i class="icon-arrow-right"></i> Schede LED<br />
                    <i class="icon-arrow-right"></i> Controller<br />
                    <i class="icon-arrow-right"></i> Alimentatore<br />
                    <i class="icon-arrow-right"></i> Cavo flat per la trasmissione dei dati

                    <p class="mt-2">
                        Va completato con</p>
                    <br>
                    <i class="icon-arrow-right"></i> Cassonetto monofacciale con profilo da 5,5 cm<br />
                    <i class="icon-arrow-right"></i> Tessuto con grafica stampata<br />
                    <i class="icon-arrow-right"></i> Animazione luminosa con effetti luce

                </div>

                <div class="col-md-4 mb-2 mb-lg-0">

                    <h2 class="title-sm dropcap">
                        Chiedi un preventivo<br>
                    </h2>
                    <img src="/assets/images/lightbox-revolution/chiedi-preventivo.jpeg" alt="Chiedi un preventivo" />
                    <p>Contattaci senza impegno, siamo a disposizione per incontri dimostrativi</p>
                    <a href="mailto:vendite@myliteled.com" class=" mt-2">vendite@myliteled.com</a>

                    <h2 class="title-sm dropcap mt-4">Scheda Tecnica</h2>
                    <a href="/assets/images/lightbox-revolution/lightbox-revolution_scheda.pdf"
                        class="btn btn-primary btn-md" target="_blank">
                        <i class="icon-arrow-down"></i> Scarica Scheda
                    </a>

                </div>
            </div>

            <div class="mb-5"></div>
        </div>

        <div class="mb-2"></div>

        <div class="bg-light-2 pt-6 pb-6">
            <div class="container">
                <div class="row">
                    <div class="col-md-5">
                        <h2 class="title mb-3">
                            FACILE CAMBIARE
                        </h2>
                        <p>Sostituisci l’immagine in pochi istanti e con una chiavetta USB carica un’altra animazione.
                        </p>
                        <p>In due mosse ottieni una nuova comunicazione! </p>
                    </div>
                    <div class="col-md-5">


                        <div class="video-poster" (mouseleave)="hidePopover()" style="z-index: 9;">
                            <img alt="lightbox revolution myliteled"
                                src="/assets/images/lightbox-revolution/lightbox-revolution-dettagli.png" />

                            <div *ngFor="let popover of popovers" class="video-poster-content"
                                [ngStyle]="{ top: popover.top, left: popover.left }" [class.uk-open]="popover.isOpen">
                                <a *ngIf="!popover.isOpen" class="el-marker" (mouseover)="showPopover(popover)" href="#"
                                    uk-marker="" [attr.aria-expanded]="popover.isOpen">
                                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="9" y="4" width="1" height="11"></rect>
                                        <rect x="4" y="9" width="11" height="1"></rect>
                                    </svg>
                                </a>

                                <div *ngIf="popover.isOpen" style="width: 300px;" [style.top]="popover.top">
                                    <div class="uk-card uk-card-default uk-card-body">
                                        <img [src]="popover.imageSrc" class="mb-2" />

                                        <h3 class="title-sm">
                                            {{ popover.title }}
                                        </h3>
                                        <p [innerHTML]="popover.content"></p>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>

        <div class="mb-2"></div>

        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="video-poster">
                        <molla-image src="assets/images/lightbox-revolution/lightbox-01p.jpeg" alt="poster" width="319"
                            height="415" class="w-100">
                        </molla-image>

                        <div class="video-poster-content">
                            <a href="https://www.youtube.com/watch?v=vBPgmASQ1A0" class="btn-video btn-iframe"
                                (click)="showModal($event,'https://www.youtube.com/watch?v=vBPgmASQ1A0')"><i
                                    class="icon-play"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="video-poster">
                        <molla-image src="assets/images/lightbox-revolution/lightbox-02p.jpeg" alt="poster" width="319"
                            height="415" class="w-100">
                        </molla-image>

                        <div class="video-poster-content">
                            <a href="https://www.youtube.com/watch?v=PzgYaodDNL0" class="btn-video btn-iframe"
                                (click)="showModal($event,'https://www.youtube.com/watch?v=PzgYaodDNL0')"><i
                                    class="icon-play"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="video-poster">
                        <molla-image src="assets/images/lightbox-revolution/lightbox-03p.jpeg" alt="poster" width="319"
                            height="415" class="w-100">
                        </molla-image>

                        <div class="video-poster-content">
                            <a href="https://www.youtube.com/watch?v=19JmnOZYoPU" class="btn-video btn-iframe"
                                (click)="showModal($event,'https://www.youtube.com/watch?v=19JmnOZYoPU')"><i
                                    class="icon-play"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-2"></div>

        <div class="pt-6 pb-6 banner-container">
            <div class="container text-center">
                <h2 class="title text-white mb-3">
                    Segui Lightbox Revolution
                    sul nostro canale YouTube
                </h2>
                <a href="https://www.youtube.com/@myliteledscritteluminose"
                    class="btn btn-outline-white-2 btn-md mt-2" target="_blank">
                    <i class="icon-youtube"></i> Segui
                </a>
            </div>
        </div>

        <div class="mb-2"></div>

        <div class="container">
            <div class="row">

                <div class="col-md-12 pt-4 pb-4">
                    <h2 class="title dropcap pb-3">
                        Vantaggi<br>
                    </h2>

                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <p class="underline pb-2 pt-2 mr-5">
                                <i class="icon-check"></i> Comunicazione dinamica
                            </p>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <p class="underline pb-2 pt-2 ml-5">
                                <i class="icon-check"></i> Effetti luminosi di forte appeal
                            </p>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <p class="underline pb-2 pt-2 mr-5">
                                <i class="icon-check"></i> Schede LED modulabili
                            </p>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <p class="underline pb-2 pt-2 ml-5">
                                <i class="icon-check"></i> Messaggio pubblicitario aggiornabile
                            </p>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <p class="pt-2 mr-5">
                                <i class="icon-check"></i> Tessuto intercambiabile
                            </p>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <p class="pt-2 ml-5">
                                <i class="icon-check"></i> Cassonetto riutilizzabile
                            </p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>