import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component( {
	selector: 'molla-video-modal',
	templateUrl: './video-modal.component.html',
	styleUrls: [ './video-modal.component.scss' ]
} )

export class VideoModalComponent implements OnInit {

	@Input() url = "";

	constructor (
		private modalService: NgbActiveModal,
		public sanitizer: DomSanitizer ) {
	}

	ngOnInit (): void {
	}

	closeModal () {
		this.modalService.dismiss();
	}
}