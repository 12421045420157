<div class="dropdown category-dropdown">
    <a href="javascript:;" class="dropdown-toggle" title="Browse Categories" (click)="toggleMenu()">Prodotti LED</a>

    <div class="dropdown-menu" [class.show]="!utilsService.isSticky && isHome">
        <nav class="side-nav" itemscope itemtype="http://schema.org/SiteNavigationElement">
            <ul class="menu-vertical sf-arrows" *ngFor="let item of categories;">
                <li routerLinkActive="active">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="getMergedParams(item.CategoriaId)">{{ item.Descrizione }}</a>
                </li>                
            </ul>
        </nav>
    </div>
</div>