import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OwlModule } from 'angular-owl-carousel';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import { PagesRoutingModule } from './pages-routing.module';
import { SharedModule } from '../../shared/shared.module';

import { AboutOneComponent } from './about-one/about-one.component';
import { AboutTwoPageComponent } from './about-two/about-two.component';
import { LoginPageComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContactOnePageComponent } from './contact-one/contact-one.component';
import { ContactTwoPageComponent } from './contact-two/contact-two.component';
import { ComingSoonPageComponent } from './coming-soon/coming-soon.component';

//Mat Component
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { CaseStudyComponent } from './case-study/case-study.component';
import { CataloghiProdottiComponent } from './cataloghi-prodotti/cataloghi-prodotti.component';
import { LightboxRevolutionComponent } from './lightbox-revolution/lightbox-revolution.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';

@NgModule( {
	declarations: [
		AboutOneComponent,
		AboutTwoPageComponent,
		LoginPageComponent,
		PageNotFoundComponent,
		ContactOnePageComponent,
		ContactTwoPageComponent,
		ComingSoonPageComponent,
		CaseStudyComponent,
		CataloghiProdottiComponent,
		LightboxRevolutionComponent,
		PasswordChangeComponent,
		PasswordResetComponent
	],

	imports: [
		CommonModule,
		PagesRoutingModule,
		SharedModule,
		TranslateModule,
		RouterModule,
		OwlModule,
		HttpClientModule,
		HttpClientJsonpModule,
		ReactiveFormsModule,
		NgbModule,
    	NgbNavModule,
		//Mat-module	
		MatFormFieldModule,
		MatInputModule,
		MatCheckboxModule,
		MatSelectModule,
		MatSnackBarModule,
		MatDialogModule,
		MatIconModule,
	]
} )

export class PagesModule { }
