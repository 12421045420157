import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LoginModel } from 'src/app/shared/classes/LoginModel';
import { AnaService } from 'src/app/shared/services/ana.service';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
  selector: 'molla-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  loginModel!: LoginModel;
  
  pwForm!: FormGroup;
	loading: boolean = false;
	submitted: boolean = false;

  constructor(
    private anaService: AnaService,
    private formBuilder: FormBuilder,
		private translate: TranslateService,
		private formService: FormService,
		private toastrService: ToastrService,
		private router: Router,
  ) { }

  get f() { return this.pwForm.controls; }

  ngOnInit(): void {

    this.loginModel = JSON.parse(sessionStorage.getItem('currentUser')!);


		if (this.loginModel && this.loginModel.token) {
			this.router.navigateByUrl('/shop/dashboard');
		}

    //Carico i controlli della form
		this.pwForm = this.formBuilder.group({
			username: ['', Validators.required],
		});

  }

  async pwReset(){
    this.submitted = true;
		if (this.pwForm.invalid) {
			return;
		}
		this.loading = true;

    // Validazione dei campi del modulo
		if (!this.formService.validateForm(this.pwForm)) {
			this.submitted = true;
			return; // Esci se la validazione fallisce
		}		

		let res = await this.anaService.ResetPassword(this.f['username'].value,)

		if (res && res.code === 0) {
      this.toastrService.success(this.translate.instant('msg.fetchPwOk'))
    } else {
      this.toastrService.error((res && res.info) ? res.info : this.translate.instant('msg.fetchPwKo'))
    }
  }
}
