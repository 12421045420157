import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { categories } from 'src/app/shared/classes/categories.model';
import { ApiService } from 'src/app/shared/services/api.service';

declare var $: any;

@Component({
	selector: 'molla-mobile-menu',
	templateUrl: './mobile-menu.component.html',
	styleUrls: ['./mobile-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class MobileMenuComponent implements OnInit, OnDestroy {

	searchTerm = "";
	categories!:categories[];

	private subscr: Subscription;

	constructor(
		private router: Router,
		private apiService: ApiService
	) {
		this.subscr = this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.hideMobileMenu();
			}
		});
	}

	ngOnInit(): void {		
		this.caricaCategorie();
	}

	async caricaCategorie(){
		const fromDb = undefined;
		const params = fromDb || {};
		params.LIVELLO = 1;
		params.lingua = 'IT';

		this.categories = await this.apiService.fetchCategories(params);
	}

	ngOnDestroy(): void {
		this.subscr.unsubscribe();
	}

	submenuToggle(e) {
		const parent: HTMLElement = e.target.closest('li');
		const submenu: HTMLElement = parent.querySelector('ul');

		if (parent.classList.contains('open')) {
			$(submenu).slideUp(300, function () {
				parent.classList.remove('open');
			});
		}
		else {
			$(submenu).slideDown(300, function () {
				parent.classList.add('open');
			});
		}

		e.preventDefault();
		e.stopPropagation();
	}

	hideMobileMenu() {
		document.querySelector('body').classList.remove('mmenu-active');
		document.querySelector('html').removeAttribute('style');
	}

	submitSearchForm(e: any) {
		e.preventDefault();
		this.searchTerm = e.currentTarget.querySelector('.form-control').value;
		this.router.navigate(['/shop/sidebar/list'], { queryParams: { searchTerm: this.searchTerm } });
	}
}
