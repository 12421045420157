import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component( {
	selector: 'molla-quantity-input',
	templateUrl: './quantity-input.component.html',
	styleUrls: [ './quantity-input.component.scss' ]
} )

export class QuantityInputComponent implements OnInit, OnChanges {

	@Input() value = 1;
	@Input() max = 10000;
	@Input() adClass = "";
	@Input() pzConf = 1;
	@Output() changeQty: EventEmitter<number>;

	current =  1;

	constructor (
		private toastrService: ToastrService,
		private translate: TranslateService
		
	) {
		this.changeQty = new EventEmitter<number>();
	}

	ngOnChanges (): void {
		this.current = this.value;
	}

	ngOnInit (): void {
	}

	increment () {
		if ( this.max <= 0 || this.current >= this.max )
			return;

		//this.current++;
		this.current = this.current + (1 * this.pzConf);
		this.changeQty.emit( this.current );
	}

	decrement () {
		if ( this.current > 1 ) {
			//this.current--;
			const newValue = this.current - (1 * this.pzConf);

			if (newValue >= this.pzConf) {

				this.current = newValue;				
				this.changeQty.emit( this.current );

			} else {
				this.toastrService.warning(`${this.translate.instant('ctrl.pcspack')} ${this.pzConf}`)
			}
		}
	}

	// changeCurrent ( event: any ) {
	// 	if ( parseInt( event.currentTarget.value ) < this.max && parseInt( event.currentTarget.value ) > 0 ) {
	// 		this.current = parseInt( event.currentTarget.value );
	// 		this.changeQty.emit( this.current );
	// 	} else {
	// 		event.currentTarget.value = this.current;
	// 	}
	// }


	changeCurrent(event: any) {
		const newValue = parseInt(event.currentTarget.value);
	  
		if (newValue > 0 && newValue <= this.max) {
		  if (newValue % this.pzConf === 0) {
			this.current = newValue;
			this.changeQty.emit(this.current);
		  } else {
			// Il nuovo valore deve essere un multiplo o divisore di pzConf
			//console.log("Il valore deve essere un multiplo o divisore di pzConf");			
			event.currentTarget.value = this.current.toString();
			this.toastrService.warning(`${this.translate.instant('ctrl.pcspack')} ${this.pzConf}`)
		  }
		} else {
		  // Il nuovo valore deve essere compreso tra 1 e max
		  //console.log("Il nuovo valore deve essere compreso tra 1 e max");		  
		  event.currentTarget.value = this.current.toString();
		  this.toastrService.warning(`${this.translate.instant('ctrl.pcsminmax')} ${this.max}`)
		}
	  }
}