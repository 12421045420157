<main class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/">Home</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="javascript:void();">{{ "cataloghi.titolo1" | translate }}</a>
                </li>
            </ol>
        </div>
    </nav>

    <div class="page-content skeleton-body">
        <div class="container">
            <div class="page-header page-header-big text-center"
                style="background-image: url('assets/images/contact-header-bg.jpg')">
                <h1 class="page-title text-white">{{ "cataloghi.titolo2" | translate }}
                    <span class="text-white">
                        {{ "cataloghi.titolo3" | translate }}
                        <br>
                        {{ "cataloghi.titolo4" | translate }}
                    </span>
                </h1>
            </div>
        </div>
        <div class="container">
            <article class="entry single-entry entry-fullwidth" *ngFor="let item of data">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="entry-body">

                            <h2 class="title-xl">
                                {{ item?.title }}
                            </h2>

                            <div class="title title-light">
                                {{ item?.content }}
                            </div>

                            <a [href]="item?.attachment[0]?.url" *ngIf="item?.attachment[0] !== undefined"
                            class="btn btn-primary btn-md mt-3 mr-1 pl-3 pr-5" target="_blank">
                            <i class="icon-arrow-down"></i> Download Brochure
                            </a>

                            <a [href]="item?.video" *ngIf="item?.video !== undefined"
                            class="btn btn-primary btn-md mt-3 mr-1 pl-3 pr-5" target="_blank">
                            <i class="icon-youtube"></i> Guarda il video
                            </a>

                            <a [href]="item?.url" *ngIf="item?.url !== undefined"
                            class="btn btn-primary btn-md mt-3 mr-1 pl-3 pr-5">
                            <i class="la la-chain"></i> Vai alla pagina
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <molla-image class="with-shadow" [src]="SERVER_URL + item?.pictures[0].url" [alt]="item?.title" height="350px" width="248px">
						</molla-image>
                    </div>
                </div>
            </article>
        </div>
    </div>
</main>