import { Component, OnInit } from '@angular/core';

import { ModalService } from 'src/app/shared/services/modal.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

import { introSlider, brandSlider } from '../data';
import { categories } from 'src/app/shared/classes/categories.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from 'src/app/shared/services/form.service';
import { ToastrService } from 'ngx-toastr';
import { JsonLDService } from 'src/app/shared/services/json-ld.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'molla-index',
	templateUrl: './index.component.html',
	styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {

	prodColl1: any[] = [];
	prodColl2: any[] = [];
	prodColl3: any[] = [];
	prodColl4: any[] = [];
	loaded1: boolean = false;
	loaded2: boolean = false;
	loaded3: boolean = false;
	loaded4: boolean = false;

	products = [];
	categories!:categories[];
	introSlider = introSlider;
	brandSlider = brandSlider;

	joinForm!: FormGroup;
	submitted: boolean = false;

	constructor(
		public apiService: ApiService,
		public utilsService: UtilsService,
		private formBuilder: FormBuilder,
		private translate: TranslateService,
		private formService: FormService,
		private toastrService: ToastrService,
		private title: Title,
		private meta: Meta,
		private jsonLdService: JsonLDService) {

	}

	ngOnInit(): void {
		//log
		this.utilsService.logAction(`Nuovo accesso al sito.`).then(response => {
			//console.log(response);
		})
		.catch(error => {
			//console.log(error);
		});

		this.caricaCategorie();

		this.joinForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			privacy1: [null, Validators.requiredTrue],
			privacy2: [null, Validators.requiredTrue]
		});

		let paramsColl1:any={}
		paramsColl1.searchTerm="promo";	
		paramsColl1.OrdDir="random";		
		this.apiService.fetchShopData(paramsColl1, 20).subscribe(result => {
			this.prodColl1 = JSON.parse(result!.products);
			this.loaded1=true;
		})

		let paramsColl2:any={}
		paramsColl2.category="03.50";
		paramsColl1.OrdDir="random";			
		this.apiService.fetchShopData(paramsColl2, 20).subscribe(result => {
			this.prodColl2 = JSON.parse(result!.products);
			this.loaded2=true;
		})


		let paramsColl3:any={}
		paramsColl3.category="03.01";
		paramsColl3.OrdDir="random";			
		this.apiService.fetchShopData(paramsColl3, 20).subscribe(result => {
			this.prodColl3 = JSON.parse(result!.products);
			this.loaded3=true;
		})


		let paramsColl4:any={}
		paramsColl4.category="03.15";
		paramsColl4.OrdDir="random";			
		this.apiService.fetchShopData(paramsColl4, 20).subscribe(result => {
			this.prodColl4 = JSON.parse(result!.products);
			this.loaded4=true;
		})

		this.title.setTitle('MyLiteLED')
		this.meta.updateTag({ name: 'description', content: 'LED,  insegne, cassonetti, lettere scatolate' })
		this.meta.updateTag({ name: 'keywords', content: 'Prodotti a LED per insegne, cassonetti, lettere scatolate.' })
		this.jsonLdService.insertSchema(JsonLDService.websiteSchema('Organization'));
	}

	async caricaCategorie(){
		const fromDb = undefined;
		const params = fromDb || {};
		params.LIVELLO = 1;
		params.lingua = 'IT';

		this.categories = await this.apiService.fetchCategories(params);
	}

	async joinSend() {
		// Validazione dei campi del modulo
		if (!this.formService.validateForm(this.joinForm)) {
			this.submitted=true;
			return; // Esci se la validazione fallisce
		}		

		this.formService.sendJoinNewsletter(this.joinForm).subscribe({

			next: data => {
				if (data.code === 0) {
					/*this.modalService.openSnackBar(this.translate.instant('msg.confReg'))*/
					this.toastrService.success(this.translate.instant('msg.confContct'))
				} else {
					//this.modalService.openAlertDialog(data!.info)
					this.toastrService.error(data!.info)
				}
					
			},
			error:  error => {
				if (error.status ) {
					//this.alertService.error(error.message);
					//this.modalService.openAlertDialog(error.message);
					this.toastrService.error(error.message)
				} else {          
					//this.alertService.error(error.info);
					//this.modalService.openAlertDialog(error.info);
					this.toastrService.error(error.info)
				}			
			}
		});
	}
}