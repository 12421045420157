<div class="product">
    <figure class="product-media">
        <span class="product-label label-new" *ngIf="product._new">New</span>
        <span class="product-label label-sale" *ngIf="product.sale_price">Sale</span>
        <span class="product-label label-top" *ngIf="product.top">Top</span>
        <span class="product-label label-out" *ngIf="!product.stock || product.stock == 0">Out Of Stock</span>

        <a [routerLink]="['/product/default/'+ product.slug]">
            <molla-image [src]="product.sm_pictures && product.sm_pictures[0]?.url" alt="Product" class="product-image">
            </molla-image>
            <molla-image [src]="product.sm_pictures[1]?.url" alt="Product" class="product-image-hover"
                *ngIf="product.sm_pictures && product.sm_pictures.length >= 2"></molla-image>
        </a>

        <div class="product-action-vertical">
            <a href="javascript:;" class="btn-product-icon btn-wishlist" [class.added-to-wishlist]="isInWishlist()"
                (click)="addToWishlist($event)">
                <span>{{ isInWishlist() ? 'go' : 'add' }} to Wishlist</span>
            </a>
            <!--a href="javascript:;" class="btn-product-icon btn-quickview" title="Quick view"
                (click)="quickView($event)"><span>Quick view</span></a-->
        </div>

        <div      
        class="product-action"
        *ngIf="(loginModel && loginModel.token) && (product.stock && product.stock !== 0)">
            <a [routerLink]="['/product/default/'+ product.slug]" class="btn-product btn-cart btn-select"
                *ngIf="product.variants.length > 0 else cartBlock">
                <span>select options</span>
            </a>
            <ng-template #cartBlock>
                <button class="btn-product btn-cart" (click)="addToCart($event)">
                    <span>{{ "btn.addtocart" | translate}}</span>
                </button>
            </ng-template>
        </div>
    </figure>

    <div class="product-body">
        <div class="product-cat">
            <span *ngFor="let cat of product.category; let i =index">{{product.category[0].name}}
                <a [routerLink]="['/shop/sidebar/list']" [queryParams]="{category: cat.slug}">{{ cat.name }}</a>{{ i < product.category.length - 1 ? ',' : '' }}
            </span>
        </div>

        <h3 class="product-title">
            <a [routerLink]="['/product/default/'+ product.slug]">{{ product.name }}</a><p>{{product.short_desc}}</p>
        </h3>

        <div *ngIf="loginModel && loginModel.token">
            <div class="product-price" *ngIf="!product.stock || product.stock == 0 else elseBlock">
                <span class="out-price" *ngIf="product.price != null">{{ product.price.toFixed(2) | currency:currVal }}</span>
            </div>

            <ng-template #elseBlock>
                <div class="product-price" *ngIf="minPrice == maxPrice && minPrice !== 0 else elseBlock">{{ minPrice.toFixed(2) | currency:currVal }}</div>
                <ng-template #elseBlock>
                    <div class="product-price" *ngIf=" minPrice === 0 else elseBlock">{{ product.price.toFixed(2) | currency:currVal }}</div>
                    <ng-template #elseBlock>
                        <div class="product-price" *ngIf="product.variants.length == 0 else elseBlock">
                            <span class="new-price">{{ minPrice.toFixed(2) | currency:currVal }}</span>
                            <span class="old-price">{{ maxPrice.toFixed(2) | currency:currVal }}</span>
                        </div>
                        <ng-template #elseBlock>
                            <div class="product-price">{{minPrice.toFixed(2) | currency:currVal }}&ndash;{{maxPrice.toFixed(2) | currency:currVal }}</div>
                        </ng-template>
                    </ng-template>
                </ng-template>
            </ng-template>
        </div>

        <!--div class="ratings-container">
            <div class="ratings">
                <div class="ratings-val" [style.width]="product.ratings * 20 + '%'"></div>
                <span class="tooltip-text">{{ product.ratings.toFixed(2) }}</span>
            </div>
            <span class="ratings-text">( {{ product.review }} Reviews )</span>
        </div-->
    </div>
</div>