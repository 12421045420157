<form [formGroup]="loginForm" (submit)="login()">

    <input type="text" class="form-control" formControlName="email"
        [placeholder]="'fld.email' | translate">
    <div *ngIf="submitted && ((loginForm.get('email').hasError('required')) || (loginForm.get('email').hasError('pattern') && !loginForm.get('email').hasError('required')))" class="required">
        {{ "ctrl.email" | translate }}
    </div>

    <input type="password" class="form-control" formControlName="password"
        [placeholder]="'fld.password' | translate">
    <div *ngIf="submitted && loginForm.get('password').hasError('required')"
        class="required">
        {{ "ctrl.password" | translate }}
    </div>

    <div class="form-footer">
        <button type="submit" class="btn btn-outline-primary-2">
            <span>LOG IN</span>
            <i class="icon-long-arrow-right"></i>
        </button>

        <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" formControlName="rememberme" id="rememberme">
            <label class="custom-control-label" for="rememberme">{{ "loginmodal.rememberme" | translate }}</label>
        </div>

        <a href="javascript:;" routerLink="/pages/pw-reset" class="forgot-link">{{ "loginmodal.forgotpw" | translate}}</a>
    </div>
</form>

<div class="form-choice d-none">
    <p class="text-center">or sign in with</p>
    <div class="row">
        <div class="col-sm-6">
            <a href="javascript:;" class="btn btn-login btn-g">
                <i class="icon-google"></i>
                Login With Google
            </a>
        </div>
        <div class="col-sm-6">
            <a href="javascript:;" class="btn btn-login btn-f">
                <i class="icon-facebook-f"></i>
                Login With Facebook
            </a>
        </div>
    </div>
</div>