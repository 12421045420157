import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginModel } from '../../classes/LoginModel';
import { AuthenticateService } from '../../services/authenticate.service';
import * as sha from 'js-sha256';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'molla-login-form',
	templateUrl: './login-form.component.html',
	styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
	loginModel!: LoginModel;
	loginForm!: FormGroup;
	//response!: WSResponseModel;
	loading: boolean = false;
	submitted: boolean = false;

	@Input() backpage: any;

	constructor(
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private authenticateService: AuthenticateService
	) { 
		//Carico i controlli della form
		this.loginForm = this.formBuilder.group({
			email: ['', Validators.required],
			password: ['', Validators.required],
			rememberme: [false],
		});
	}

	get l() { return this.loginForm.controls; }

	ngOnInit(): void {

		this.route.queryParams.subscribe(params => {
			this.backpage = params['backpage'];
		});
		

		if (localStorage.getItem('email')) {
			this.l['email'].setValue(localStorage.getItem('email'));
		}
		if (localStorage.getItem('password')) {
			this.l['password'].setValue(localStorage.getItem('password'));
		}

	}

	async login() {
		this.submitted = true;
		if (this.loginForm.invalid) {
			return;
		}
		this.loading = true;


		this.loginModel = await this.authenticateService.authenticate(this.l['email'].value, sha.sha256(this.l['password'].value), 1);
		if (this.loginModel.token) {

			if (this.l['rememberme']?.value == true) {
				localStorage.setItem('email', this.loginForm.get('email').value);
				localStorage.setItem('password', this.loginForm.get('password').value);
			}

			if (this.backpage) {
				window.location.href = this.backpage;
			} else {
				window.location.reload();
			}
		}

	}
}
