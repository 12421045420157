import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { categories } from 'src/app/shared/classes/categories.model';
import { ApiService } from 'src/app/shared/services/api.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'molla-category-menu',
  templateUrl: './category-menu.component.html',
  styleUrls: ['./category-menu.component.scss']
})

export class CategoryMenuComponent implements OnInit, OnDestroy {
  isHome = false //true; //richiesto da Giulia 27/11/23
  //Nel frattempo se può correggere il menu a tendina di "prodotti LED" che scende in automatico ogni volta che si apre o ricarica la pagina. Se per cortesia può fare in modo che si apra solo quando ci si passa sopra con il mouse e non che sia aperto in automatico.
  @Input() categories!: categories[];

  private subscr: Subscription;
  private params: any;

  constructor(
	public activeRoute: ActivatedRoute,
	public router: Router,
    public utilsService: UtilsService,
    public apiService: ApiService
  ) {
    // this.subscr = this.router.events.subscribe(event => {
    //   if (event instanceof NavigationStart || event instanceof NavigationEnd) {
    //     this.isHome = event.url === "/";
    //   }
    // });

    this.activeRoute.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit(): void {
    // Puoi eseguire l'inizializzazione qui se necessario
  }

  getMergedParams(categoriaId: string): any {
    //return { ...this.params, category: categoriaId };
    return { category: categoriaId };
  }

  ngOnDestroy(): void {
    this.subscr.unsubscribe();
  }

  toggleMenu() {
    const dropdownMenu = document.querySelector('.category-dropdown .dropdown-menu');
    if (dropdownMenu) {
      dropdownMenu.classList.toggle('show');
    }
  }
}