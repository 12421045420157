<header class="header header-10 header-intro-clearance">
    <div class="header-top">
        <div [class]="containerClass">
            <div class="header-left">
                <ul class="top-menu">
                    <li>
                        <a href="javascript:;">Contatti</a>
                        <ul>
                            <li>
                                <a href="tel:+390444821261">
                                    <i class="icon-phone"></i>TEL: +39 0444 82 12 61
                                </a>
                            </li>
                            <li>
                                <a href="mailto:info@myliteled.com">
                                    <i class="icon-envelope"></i>EMAIL: info@myliteled.com
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div class="header-right">

                <ul class="top-menu">
                    <li class="login" *ngIf="!loginModel">
                        <a href="javascript:;" (click)="showLoginModal($event)">LoginI</a>
                    </li>
                    <li class="dashboard" *ngIf="loginModel">
                        <a href="javascript:;" routerLink="shop/dashboard">My Account</a>
                    </li>
                </ul>

            </div>
        </div>
    </div>

    <div class="header-middle">
        <div [class]="containerClass">
            <div class="header-left">
                <molla-mobile-button></molla-mobile-button>

                <a routerLink="/" class="logo">
                    <img src="assets/images/home/logo.png" alt="MyLiteLED" width="220" height="60">
                </a>
            </div>

            <div class="header-center">
                <molla-header-search [categories]="categories"></molla-header-search>
            </div>

            <div class="header-right">
                <div class="header-dropdown-link">

                    <a href="javascript:;" (click)="showLoginModal($event)" class="wishlist-link" title="Account">
                        <i class="icon-user"></i>
                        <span class="wishlist-txt">{{ this.loginModel && this.loginModel.username ?
                            this.loginModel.username : 'Account' }}</span>
                    </a>

                    <molla-wishlist-menu></molla-wishlist-menu>

                    <molla-cart-menu></molla-cart-menu>
                </div>
            </div>
        </div>
    </div>

    <div class="sticky-wrapper" [style.height]="utilsService.isSticky? utilsService.stickyHeight + 'px' : '' ">
        <div class="header-bottom sticky-header" [class.fixed]="utilsService.isSticky">
            <div [ngClass]="containerClass">
                <div class="header-left">
                    <molla-category-menu [categories]="categories"></molla-category-menu>
                </div>

                <div class="header-center">
                    <molla-main-menu></molla-main-menu>
                </div>

                <div class="header-right overflow-hidden">
                    <i class="la la-lightbulb-o"></i>
                    <p><a [routerLink]="['/shop/sidebar/3cols']" [queryParams]="{ searchTerm: 'promo' }">{{
                            "products.promo" | translate}}</a></p>
                </div>
            </div>
        </div>
    </div>
</header>