<main class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/">Home</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="javascript:;">Pages</a>
                </li>
                <li class="breadcrumb-item active">Account</li>
            </ol>
        </div>
    </nav>

    <div class="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17"
        style="background-image: url('assets/images/backgrounds/login-bg.jpg')">
        <div class="container form-tab">
            <div class="form-box">
                <ul ngbNav #nav="ngbNav" class="justify-content-center nav nav-pills">
                    <li ngbNavItem="registration">
                        <a ngbNavLink>{{ "loginmodal.register" | translate}}</a>
                        <ng-template ngbNavContent>
                            <molla-manage-user-data [newUser]="true" [backpage]="'shop/dashboard'"></molla-manage-user-data>                    
                        </ng-template>
                    </li>

                    <li ngbNavItem="login">
                        <a ngbNavLink>{{ "loginmodal.singin" | translate}}</a>
                        <ng-template ngbNavContent>
                            <molla-login-form [backpage]="'shop/dashboard'"></molla-login-form>        
                        </ng-template>
                    </li>
                </ul>

                <div [ngbNavOutlet]="nav"></div>
            </div>
        </div>
    </div>
</main>