import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutOneComponent } from './about-one/about-one.component';
import { AboutTwoPageComponent } from './about-two/about-two.component';
import { LoginPageComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContactOnePageComponent } from './contact-one/contact-one.component';
import { ContactTwoPageComponent } from './contact-two/contact-two.component';
import { CaseStudyComponent } from './case-study/case-study.component';
import { CataloghiProdottiComponent } from './cataloghi-prodotti/cataloghi-prodotti.component';
import { LightboxRevolutionComponent } from './lightbox-revolution/lightbox-revolution.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'about',
        pathMatch: 'full'
    },
    {
        path: 'about',
        component: AboutOneComponent
    },
    {
        path: 'about-2',
        component: AboutTwoPageComponent
    },
    {
        path: '404',
        component: PageNotFoundComponent
    },
    {
        path: 'login',
        component: LoginPageComponent
    },
    {
        path: 'register',
        component: LoginPageComponent
    },      
    {
        path: 'edit-account-address',
        component: LoginPageComponent
    },      
    {
        path: 'contact',
        component: ContactOnePageComponent
    },
    {
        path: 'contact-2',
        component: ContactTwoPageComponent
    },
    {
        path: 'pw-change',
        component:PasswordChangeComponent
    }, 
    {
        path: 'pw-reset',
        component:PasswordResetComponent
    },
	{
		path: 'case-study/:slug',
		component: CaseStudyComponent
	},
    {
        path: 'cataloghi-prodotti/:slug',
        component: CataloghiProdottiComponent
    },
    {
        path: 'lightbox-revolution',
        component: LightboxRevolutionComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class PagesRoutingModule { };