import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'molla-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {title: string, message: string}) { }

  ngOnInit(): void {
  }


}
