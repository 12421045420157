<main class="main home-page skeleton-body">
    <div class="intro-slider-container">
        <owl-carousel carouselClasses="owl-simple intro-slider" [options]="introSlider">
            <div class="intro-slide"
                style="background-image: url(assets/images/home/carosello/hero-chi-siamo.jpg); background-color: #EDF2F0;">
                <div class="container intro-content" contentAnim aniName="fadeInUpShorter">
                    <div class="row">
                        <div class="col-auto">
                            <div class="text-black bg-white p-4 mb-2">
                                <h4 class="text-primary">Il partner perfetto per la tua comunicazione luminosa</h4>
                                <h1>
                                    Insegnistica, Illuminazione, Light Design
                                </h1>
                            </div>
                            <a routerLink="/pages/about" class="btn btn-primary">
                                <span>Chi siamo</span>
                                <i class="icon-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="intro-slide"
                style="background-image: url(assets/images/home/carosello/hero-flex.jpg); background-color: #edf1f0;">
                <div class="container intro-content" contentAnim aniName="fadeInUpShorter">
                    <div class="row">
                        <div class="col-auto">
                            <div class="text-black bg-white p-4 mb-2">
                                <h4>CONFIGURATORE</h4>
                                <h1>
                                    scritte luminose effetto
                                    <br /><span class="text-primary">neon</span>
                                </h1>
                            </div>
                            <a href="https://configura.scritteluminose.com" class="btn btn-primary"
                            target="_blank">
                                <span>Scopri</span>
                                <i class="icon-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="intro-slide"
                style="background-image: url(assets/images/home/carosello/hero-promo.jpg); background-color: #ffffff;">
                <div class="container intro-content" contentAnim aniName="fadeInUpShorter">
                    <div class="row">
                        <div class="col-auto">
                            <div class="text-black bg-white p-4 mb-2">
                                <h4>SCOPRI TUTTI I PRODOTTI</h4>
                                <h1>IN PROMOZIONE</h1>
                            </div>
                            <a [routerLink]="['/shop/sidebar/3cols']" [queryParams]="{ searchTerm: 'promo' }" class="btn btn-outline-light">
                                <span>VAI ALLO SHOP</span>
                                <i class="icon-long-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </owl-carousel>
    </div>

    <div class="mb-4"></div>

    <div class="container">
        <h2 class="title text-center mb-2" contentAnim aniName="blurIn">Esplora</h2>
        <div class="cat-blocks-container">
            <div class="row justify-content-md-center">
                <div class="col-lg-2 col-md-3 col-sm-4 col-6" *ngFor="let item of categories;">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: item.CategoriaId}" class="cat-block">
                        <figure>
                            <span>
                                <img [src]="item.Immagine1" [alt]="item.Descrizione" class="mb-1">
                            </span>
                        </figure>
                        <h3 class="cat-block-title">{{ item.Descrizione }}</h3>
                    </a>
                </div>                                
            </div>
        </div>
    </div>

    <div class="mb-2"></div>

    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-3">
                <div class="banner banner-1 banner-overlay" style="background-color: #ebebeb;" contentAnim
                    aniName="fadeInLeft">
                    <a routerLink="/product/default/03.17.40.05.94.00001/posterled-p1.8-da-interno-640x1920-mm-800cd">
                        <molla-image src="assets/images/home/banners/poster-home.jpg" alt="POSTERLED" width="277"
                            height="260"></molla-image>
                    </a>
                    <div class="banner-content">

                        <div class="banner-title">
                            <div class="bg-white p-3">
                                <a routerLink="/product/default/03.17.40.05.94.00001/posterled-p1.8-da-interno-640x1920-mm-800cd">
                                    <span><small>Nuovo<br>display led<br>da interno</small></span>
                                    <br /><br />
                                    POSTERLED
                                </a>
                            </div>
                        </div>

                        <a routerLink="/product/default/03.17.40.05.94.00001/posterled-p1.8-da-interno-640x1920-mm-800cd" class="banner-link bg-white text-black">
                            Shop Now
                            <i class="icon-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3 order-lg-last">
                <div class="banner banner-3 banner-overlay" style="background-color: #465d55;" contentAnim
                    aniName="fadeInRight">
                    <a [routerLink]="['shop/sidebar/list']" [queryParams]="{searchTerm: 'promo'}">
                        <molla-image src="assets/images/home/banners/promo-home.jpg" alt="Articoli in promozione" width="277"
                            height="260"></molla-image>
                    </a>
                    <div class="banner-content">

                        <div class="banner-title">
                            <div class="bg-white p-3">
                                <a [routerLink]="['shop/sidebar/list']" [queryParams]="{searchTerm: 'promo'}">
                                    Articoli<br>in promozione
                                </a>
                            </div>
                        </div>

                        <a [routerLink]="['shop/sidebar/list']" [queryParams]="{searchTerm: 'promo'}" class="banner-link bg-white text-black">
                            Shop Now
                            <i class="icon-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="banner banner-2 banner-overlay" style="background-color: #d28679;" contentAnim
                    aniName="fadeInUpShorter">
                    <a href="https://configura.scritteluminose.com" target="_blank">
                        <molla-image src="assets/images/home/banners/flex-home.jpg" alt="Tuboflex" width="576"
                            height="260"></molla-image>
                    </a>
                    <div class="banner-content">

                        <h3 class="banner-title text-white">
                            <a href="https://configura.scritteluminose.com/" target="_blank">
                                Configuratore per insegne in 
                                <br />
                                <span>Tuboflex</span>
                            </a>
                        </h3>

                        <a href="https://configura.scritteluminose.com/" target="_blank" class="banner-link">
                            Calcola il prezzo
                            <i class="icon-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-3"></div>

    <div class="bg-light pt-3 pb-5">
        <molla-products-collection *ngIf="prodColl1.length > 0" [products]="prodColl1.slice(0,10)" [loaded]="loaded1" [titolo]="'products.promo' | translate ">
        </molla-products-collection>
    </div>

    <div class="mb-3"></div>

    <molla-products-collection *ngIf="prodColl2.length > 0"  [products]="prodColl2" [loaded]="loaded2" [titolo]="'STRIP LED'">
    </molla-products-collection>

    <div class="mb-3"></div>

    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="banner banner-4 banner-overlay banner-overlay-light" style="background-color: #ebebeb;">
                    <a href="https://www.youtube.com/@myliteledscritteluminose" target="_blank">
                        <img src="assets/images/home/banners/youtube-home.jpg" alt="Youtube" width="575" height="260">
                    </a>
                    <div class="banner-content">
                        <h4 class="banner-subtitle text-white">
                            <a href="https://www.youtube.com/@myliteledscritteluminose" target="_blank">Youtube</a>
                        </h4>

                        <h3 class="banner-title text-white" style="max-width: 50%;">
                            <a href="https://www.youtube.com/@myliteledscritteluminose" target="_blank">
                                Scopri le ultime novità sul nostro canale Youtube
                            </a>
                        </h3>

                        <a href="https://www.youtube.com/@myliteledscritteluminose" target="_blank"
                            class="banner-link banner-link-dark">
                                Discover Now<i class="icon-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="banner banner-5 banner-overlay" style="background-color: #808a95;">
                    <a href="https://novapubblicita.com" target="_blank">
                        <img src="assets/images/home/banners/nova-home.jpg" alt="Scopri il nostro mondo" width="575" height="260">
                    </a>
                    <div class="banner-content">
                        <h3 class="banner-title" style="max-width: 50%;">
                            <div class="bg-white text-black p-3">
                                <a href="https://novapubblicita.com" target="_blank">
                                    Scopri il nostro mondo
                                </a>
                            </div>
                        </h3>

                        <a href="https://novapubblicita.com" class="banner-link bg-white text-black">
                            Discover Now
                            <i class="icon-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <molla-products-collection *ngIf="prodColl3.length > 0"  [products]="prodColl3" [loaded]="loaded3" [titolo]="'ACCESSORI'">
    </molla-products-collection>

    <div class="mb-3"></div>

    <molla-products-collection *ngIf="prodColl4.length > 0"  [products]="prodColl4" [loaded]="loaded4" [titolo]="'CONTROLLER'">
    </molla-products-collection>

    <div class="mb-3"></div>

    <div class="container">
        <h2 class="title title-border mb-3" contentAnim aniName="blurIn">Qualità e <span class="text-primary">Sicurezza</span></h2>
        <owl-carousel carouselClasses="brands-border owl-simple brand-slider mb-3" [options]="brandSlider">
            <a href="javascript:;" class="brand">
                <img src="assets/images/quality-regulatory/logo-ce.jpg" alt="CE">
            </a>

            <a href="javascript:;" class="brand">
                <img src="assets/images/quality-regulatory/logo-cree.jpg" alt="CREE">
            </a>

            <a href="javascript:;" class="brand">
                <img src="assets/images/quality-regulatory/logo-enec.jpg" alt="ENEC">
            </a>

            <a href="javascript:;" class="brand">
                <img src="assets/images/quality-regulatory/logo-lg.jpg" alt="LG Innotek">
            </a>

            <a href="javascript:;" class="brand">
                <img src="assets/images/quality-regulatory/logo-mean-well.jpg" alt="MW">
            </a>

            <a href="javascript:;" class="brand">
                <img src="assets/images/quality-regulatory/logo-osram.jpg" alt="OSRAM">
            </a>

            <a href="javascript:;" class="brand">
                <img src="assets/images/quality-regulatory/logo-rohs.jpg" alt="RoHS">
            </a>

            <a href="javascript:;" class="brand">
                <img src="assets/images/quality-regulatory/logo-ru.jpg" alt="RU">
            </a>
        </owl-carousel>
    </div>

    <div class="cta cta-horizontal cta-horizontal-box bg-primary">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-2xl-5col col-12" contentAnim aniName="fadeInLeftShorter">
                    <h3 class="cta-title text-white">Join Our Newsletter</h3>

                    <p class="cta-desc text-white">Iscriviti per restare aggiornato e ricevere promozioni</p>
                </div>

                <div class="col-3xl-5col col-12" contentAnim aniName="fadeInRightShorter">
                    <form [formGroup]="joinForm" (submit)="joinSend()">
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="email"
                                [placeholder]="'fld.email' | translate" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-white-2" type="submit">
                                    <span>{{ "btn.subscribe" | translate | uppercase }}</span>
                                    <i class="icon-long-arrow-right"></i>
                                </button>
                            </div>                            
                        </div>
                        <div *ngIf="submitted && ((joinForm.get('email').hasError('required')) || (joinForm.get('email').hasError('pattern') && !joinForm.get('email').hasError('required')))" class="required">
                            {{ "ctrl.email" | translate }}
                        </div>
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input"
                                id="privacy1"
                                formControlName="privacy1"
                                [placeholder]="'privacy.dichiarazione' | translate"
                                (change)="joinForm.get('privacy1').updateValueAndValidity()">
                            <label class="custom-control-label" for="privacy1">
                                {{ "privacy.dichiarazione" | translate}}</label>
                        </div>
                        <div *ngIf="submitted && joinForm.get('privacy1').hasError('required')"
                            class="required">
                            {{ "ctrl.dichiarazione" | translate }}
                        </div>
                
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input"
                                id="privacy2"
                                formControlName="privacy2"
                                [placeholder]="'privacy.consenso' | translate"
                                (change)="joinForm.get('privacy2').updateValueAndValidity()">
                            <label class="custom-control-label" for="privacy2">
                                {{ "privacy.consenso" | translate}}</label>
                        </div>
                        <div *ngIf="submitted && joinForm.get('privacy2').hasError('required')"
                            class="required">
                            {{ "ctrl.consenso" | translate }}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <!--molla-blog-collection [posts]="posts" [loaded]="loaded"></molla-blog-collection-->

    <div class="icon-boxes-container bg-white">
        <div class="container" contentAnim aniName="fadeInUpShorter">
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="icon-box icon-box-side">
                        <span class="icon-box-icon">
                            <i class="icon-rocket"></i>
                        </span>
                        <div class="icon-box-content">
                            <h3 class="icon-box-title">Spedizione</h3>

                            <p>24h con ordini entro le 12.00</p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="icon-box icon-box-side">
                        <span class="icon-box-icon">
                            <i class="icon-rotate-left"></i>
                        </span>

                        <div class="icon-box-content">
                            <h3 class="icon-box-title">Reso</h3>

                            <p>Vedi condizioni di reso</p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="icon-box icon-box-side">
                        <span class="icon-box-icon">
                            <i class="icon-info-circle"></i>
                        </span>

                        <div class="icon-box-content">
                            <h3 class="icon-box-title">Promozioni</h3>

                            <p>Ogni mese articoli in promozione.<br>
                            Consulta l'area ARTICOLI IN PROMOZIONE
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="icon-box icon-box-side">
                        <span class="icon-box-icon">
                            <i class="icon-life-ring"></i>
                        </span>

                        <div class="icon-box-content">
                            <h3 class="icon-box-title">Support</h3>

                            <p>I nostri tecnici sono a disposizione per un consulto telefonico o via mail in orario ufficio</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>