import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { WSResponseModel } from '../classes/WSResponseModel';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class FormService {

	constructor(
		public http: HttpClient,
		private toastrService: ToastrService,
		private translate: TranslateService
	) { }

	validateForm(form: FormGroup, expandedItems: { field: string, value: string, errorMessage: string }[] = []): boolean {
		let errorMessage = ``;

		for (const controlName in form.controls) {
			if (form.controls.hasOwnProperty(controlName)) {
				const control = form.controls[controlName];

				if (control && control.invalid) {

					const errors = control.errors;
					let label = controlName;

					// Ottenere il valore del placeholder se presente
					const inputElement = document.querySelector(`[formControlName="${controlName}"]`);
					if (inputElement instanceof HTMLInputElement) {
						label = inputElement.placeholder || controlName;
					}
					if (inputElement instanceof HTMLTextAreaElement) {
						label = inputElement.placeholder || controlName;
					}
					if (inputElement instanceof HTMLSelectElement) {
						const selectedIndex = inputElement.selectedIndex;
						if (selectedIndex !== -1) {
							const selectedOption = inputElement.options[selectedIndex];
							label = selectedOption.outerText || controlName;
						} else {
							// Nessuna opzione selezionata, usa il nome del controllo come fallback
							label = controlName;
						}
					}

					if (errors && errors['required']) {
						errorMessage += `- ${label}<br>`;
					}
				}
			}
		}

		// Aggiungi i messaggi di errore personalizzati per gli elementi espansi
		for (const item of expandedItems) {
			if (item.errorMessage) {
			  errorMessage += `- ${item.errorMessage}<br>`;
			}
		  }

		if (errorMessage != "") {
			errorMessage = `${this.translate.instant('ctrl.ctrlfld')}:<br>${errorMessage}`;
			this.toastrService.error(errorMessage, this.translate.instant('ctrl.ctrlfldtit'));
			return false;
		}
		else {
			return true
		}
	}

	sendFormContatto(form: FormGroup) {
		const httpOptions = {
			headers: new HttpHeaders({
				'EmailMailFrom': environment.backend.EmailFrom,
				'EmailMailTo': environment.backend.EmailTo,
				'Content-Type': 'application/json'
			}),
			rejectUnauthorized: false,
		};

		const body = form.getRawValue();

		return this.http.post<WSResponseModel>(
			`${environment.backend.baseURL}/CRM/CRMPost/sendFormContatto`,
			body,
			httpOptions
		);
	}

	sendJoinNewsletter(form: FormGroup) {
		const httpOptions = {
			headers: new HttpHeaders({
				'EmailMailFrom': environment.backend.EmailFrom,
				'EmailMailTo': environment.backend.EmailTo,
				'Content-Type': 'application/json'
			}),
			rejectUnauthorized: false,
		};

		const body = form.getRawValue();

		return this.http.post<WSResponseModel>(
			`${environment.backend.baseURL}/CRM/CRMPost/sendJoinNewsletter`,
			body,
			httpOptions
		);
	}
	
}
