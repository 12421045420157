import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'pages-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PageNotFoundComponent implements OnInit {
  private previousUrl: string;

  constructor(
    private utilsService: UtilsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Ascolta gli eventi di navigazione
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // L'URL precedente è disponibile in event.url
      this.previousUrl = event.url;
      
      this.utilsService.logAction(`Pagina non trovata - ${this.previousUrl}`).then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
    });
  }
}
