//import { FunzioniModel } from "./DbModel/FunzioniModel";

export class prodFiltri {
    searchTerm!: string;
    tags!: string;
    isTop!: boolean;
    isNew!: boolean;
    isFeatured!: boolean;
    isPromo!: boolean;


    constructor(searchTerm:string,tags:string,isTop:boolean,isNew:boolean,isFeatured:boolean,isPromo:boolean){

        this.searchTerm=searchTerm;
        this.tags=tags;
        this.isTop=isTop;
        this.isNew=isNew;
        this.isFeatured=isFeatured;
        this.isPromo=isPromo;

    }
}