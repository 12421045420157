// Cart
export const UPDATE_CART_HEADER = 'UPDATE_CART_HEADER';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const UPDATE_CART = "UPDATE_CART";

// WishList
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE';
export const REMOVE_ALL_COMPARE = 'REMOVE_ALL_COMPARE';

// Refresh store
export const REFRESH_STORE = 'REFRESH_STORE';

//Regex
export const pivaRegex = '^([a-zA-Z]{2}[0-9]{11}|[0-9]{11})$';
export const telRegex = '^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$';
export const emailRegex = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$';
