<nav class="main-nav" itemscope itemtype="http://schema.org/SiteNavigationElement">
    <ul class="menu sf-arrows">
        <li
            [class.active]="!current || current === '/'">
            <a routerLink="/">Home</a>
        </li>

        <li            
            [class.active]="current.includes('/pages/about')">
            <a routerLink="/pages/about">MyLiteLED</a>
        </li>

        <li class="megamenu-container"
            [class.item]="current=='/'"            
            [class.active]="current.includes('/pages/case-study')">
            <a routerLink="/" class="sf-with-ul">Case Study</a>

            <div class="megamenu demo">
                <div class="menu-col">
                    <div class="menu-title">Una linea completa di prodotti LED per la comunicazione visiva luminosa.</div>

                    <div class="demo-list">
                        <div class="demo-item">
                            <a href="\pages\case-study\un-insegna-innovativa-ed-ecosostenibile">
                                <span class="demo-bg"
                                    style="background-image: url(/assets/images/case-study/cs-generali-336x350.jpg)"></span>
                                <span class="demo-title">UN’INSEGNA INNOVATIVA<br>
                                    ed ecosostenibile</span>
                            </a>
                        </div>

                        <div class="demo-item">
                            <a href="\pages\case-study\una-grande-insegna-da-progettare-nel-dettaglio">
                                <span class="demo-bg"
                                    style="background-image: url(/assets/images/case-study/cs-fis-336x350.jpg)"></span>
                                <span class="demo-title">UNA GRANDE INSEGNA<br>
                                    da progettare nel dettaglio</span>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </li>      
        
        <li routerLink="/pages/contact"
            [class.active]="current.includes('/pages/contact')">
            <a routerLink="/pages/contact">Contatti</a>
        </li>

        <li routerLink="/shop/sidebar/list"
            [class.active]="current.includes('/shop')">
            <a routerLink="/shop/sidebar/list">Shop</a>
        </li>

        <li [class.active]="current.includes('/cataloghi-prodotti')">
            <a href="javascript:;" class="sf-with-ul">Cataloghi</a>

            <div class="megamenu megamenu-sm">
                <div class="row no-gutters">
                    <div class="col-md-6">
                        <div class="menu-col">
                            <div class="menu-title">Download</div>

                            <ul>
                                <li>
                                    <a routerLink="/pages/cataloghi-prodotti/catalogo">CATALOGO</a>
                                </li>
                                <li>
                                    <a routerLink="/pages/cataloghi-prodotti/flex-neonled">FLEX NEONLED SIGN</a>
                                </li>
                                <li>
                                    <a routerLink="/pages/cataloghi-prodotti/led-wall">LED WALL</a>
                                </li>
                                <li [class.active]="current.includes('pages/cataloghi-prodotti/m-flex')">
                                    <a routerLink="/pages/cataloghi-prodotti/-flex">PROFILI M-FLEX</a>
                                </li>                               
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="banner banner-overlay">
                            <a routerLink="/shop/sidebar/list">
                                <img src="assets/images/menu/banner-2.jpg" alt="Banner" width="218" height="310" />

                                <div class="banner-content banner-content-bottom">
                                    <div class="banner-title text-white">
                                        
                                        <br />

                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</nav>