// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  demo: 'eC_MyLiteLed_202303',
  SERVER_URL: 'https://www.myliteled.com',
  //IMAGE_URL: 'assets/documents/prodotti/images/',
  VALUTA: 'EUR',
  COD_IVA_DEFAULT: '22',
  COD_NO_IVA: 'FCI',
  COD_ART_SPED: 'TRASPMY',
  backend: {
    baseURL: "/server/api",
    IDDitta: 1,
    IDPagDefault: 10427,//10413//10424
    EmailFrom:'info@myliteled.com',
    EmailTo:'vendite@myliteled.com;'
    //EmailTo:'helpdesk@italyforcloud.com'
  },
  recaptcha: {
    siteKey: '6LeZyeAZAAAAAIqx5-nMKvB85f8wRgfe1Jh-e9Tz',
    //Utilizza la chiave di questo sito nel codice HTML mostrato dal tuo sito agli utenti.
    //6Ledjv8hAAAAAHmooSQyrf5ITX6IeINUk3t8qU2g
    //Utilizza questa chiave segreta per la comunicazione tra il tuo sito e reCAPTCHA.
    //6Ledjv8hAAAAAKqJiw3T_zjjKKT8IbZBPFvFnmSx
  },
  paypal: {
    paypalReturn:'shop/payment-pp-return',
    paypalCancelReturn: 'shop/checkout',
    paypalImageUrl:'assets/images/home/logo.png',
    //paypalApiUrl: 'https://api.sandbox.paypal.com/v1/payments/payment', //https://www.paypal.com/it/cgi-bin/webscr
    paypalApiUrl: 'https://api-m.paypal.com/v1/payments/payment',
    paypalClient_id: 'AfdD7-6xFLNHrUBXu8I2NDvBX7U84tkn7nW8dgEuM7Irjd2lNwZAR0vKyl-wCc1c3vk90pOMCzCnXjDb',
		paypalClient_secret: 'EBs5BJUs_GjhlO7LDPYdGJCU6dAlK2c0WmbhmCGmGD7TDnG4KtLj8ES9Y5n8slVZ0fIg9Ab-wIjuuhVH'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
