<main class="main">
    <molla-page-header [title]="'pwchange.pwchange' | translate" [subtitle]="'dashboard.myaccount' | translate"></molla-page-header>
    <nav class="breadcrumb-nav border-0 mb-0">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/">Home</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="javascript:;">Pages</a>
                </li>
                <li class="breadcrumb-item active">{{ 'pwchange.pwchange' | translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="page-content pb-0">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h2 class="title mb-1">{{"pwchange.pwchange" | translate}}</h2>
                    <p class="mb-3">{{"pwchange.infoText" | translate}}</p>


                    <form [formGroup]="pwForm" (submit)="pwChange()">

                        <input type="password" class="form-control" formControlName="pwold"  style="max-width: 300px;"
                            [placeholder]="'fld.pwold' | translate">
                        <div *ngIf="submitted && ((pwForm.get('pwold').hasError('required')) || (pwForm.get('pwold').hasError('pattern') && !pwForm.get('pwold').hasError('required')))"
                            class="required">
                            {{ "ctrl.pwold" | translate }}
                        </div>

                        <input type="password" class="form-control" formControlName="pwnew"  style="max-width: 300px;"
                            [placeholder]="'fld.pwnew' | translate">
                        <div *ngIf="submitted && ((pwForm.get('pwnew').hasError('required')) || (pwForm.get('pwnew').hasError('pattern') && !pwForm.get('pwnew').hasError('required')))"
                            class="required">
                            {{ "ctrl.pwnew" | translate }}
                        </div>

                        <input type="password" class="form-control" formControlName="pwconfirm"  style="max-width: 300px;"
                            [placeholder]="'fld.pwconfirm' | translate">
                        <div *ngIf="submitted && ((pwForm.get('pwconfirm').hasError('required')) || (pwForm.get('pwconfirm').hasError('pattern') && !pwForm.get('pwconfirm').hasError('required')))"
                            class="required">
                            {{ "ctrl.pwconfirm" | translate }}
                        </div>


                        <div class="form-footer">
                            <button type="submit" class="btn btn-outline-primary-2">
                                <span>{{ 'btn.submit' | translate | uppercase}}</span>
                                <i class="icon-long-arrow-right"></i>
                            </button>

                        </div>
                    </form>
                    <p><br></p>
                </div>
            </div>
        </div>
    </div>
</main>