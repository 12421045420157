import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CartService } from './cart.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrezziSpedizioneService {
  private prezzoSpedizioneSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  get prezzoSpedizione$(): Observable<number> {
    return this.prezzoSpedizioneSubject.asObservable();
  }
  
  constructor(
		private toastrService: ToastrService,
		private translate: TranslateService,
	) { }

  expandedItems: { field: string, value: string, errorMessage: string }[] = [];

  private prezziPerRegione: { [regione: string]: number } = {
    'VEN': 10, // Veneto: 10 euro
    'NORD': 13, // Nord: 13 euro
    'CENTRO': 15, // Centro: 15 euro
    'SUD': 18, // Sud & Isole: 18 euro
    'ESTERO': 25, // Estero: 25 euro
  };

  ottieniPrezzo(regione: string): number {
    let prezzoSpedizione:number = 0;
    const codiceRegione = regione.toUpperCase();

    if (this.prezziPerRegione.hasOwnProperty(codiceRegione)) {
      prezzoSpedizione = this.prezziPerRegione[codiceRegione];
    } else {
      // Se il codice della regione non è presente, cerca nei sottogruppi
      const sottogruppi = {
        'NORD': ['VAO', 'PIE', 'LIG', 'LOM', 'EMI', 'TRE', 'FVG'],
        'CENTRO': ['TOS', 'MAR', 'UMB', 'LAZ', 'ABR', 'MOL'],
        'SUD': ['CAM', 'PUG', 'BAS', 'CAL', 'SIC', 'SAR'],
      };

      for (const gruppo in sottogruppi) {
        if (sottogruppi[gruppo].includes(codiceRegione)) {
          prezzoSpedizione = this.prezziPerRegione[gruppo];
        }
      }      
    }

    if (prezzoSpedizione===0){
      prezzoSpedizione = Math.max(...Object.values(this.prezziPerRegione));
      this.toastrService.error(`Codice della regione non valido: ${codiceRegione}`, `Errore calcolo spese spedizione`);
    }    

    this.setPrezzoSpedizione(prezzoSpedizione);

    return prezzoSpedizione;
  }

  setPrezzoSpedizione(prezzo: number) {
    this.prezzoSpedizioneSubject.next(prezzo);
  }
}