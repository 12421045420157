import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { emailRegex } from 'src/app/core/constants/constants';
import { UtilsService } from 'src/app/shared/services/utils.service';


@Component({
	selector: 'molla-login-modal',
	templateUrl: './login-modal.component.html',
	styleUrls: ['./login-modal.component.scss']
})

export class LoginModalComponent implements OnInit {
	m_regForm!: FormGroup;
	submitted: boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private utilsService: UtilsService) {

			//Carico i controlli della form
			this.m_regForm = this.formBuilder.group({
				email: ['', { validators: [Validators.required, Validators.pattern(emailRegex)] }],
				password: ['', Validators.required],
				privacy1: [false],
				privacy2: [false]
			});

	}

	get r() { return this.m_regForm.controls; }

	ngOnInit(): void {

	}

	async register() {
		this.submitted = true;
		if (this.m_regForm.invalid)
			return;

		const url = `/pages/register?email=${this.r['email'].value}`;
		this.utilsService.setPassword(this.m_regForm.get('password').value);
		// Naviga alla pagina 'register' con i parametri nell'URL
		this.router.navigateByUrl(url);
		//window.location.href=url;
	}

	closeModal() {
		let modal = document.querySelector('.login-modal') as HTMLElement;
		if (modal)
			modal.click();
	}


}