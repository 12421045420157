<main class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a routerLink="/">Home</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="javascript:;">Pages</a>
                </li>
                <li class="breadcrumb-item active">{{"contactus.contactus" | translate}}</li>
            </ol>
        </div>
    </nav>

    <div class="container">
        <div class="page-header page-header-big pt-0 pb-0"
          id="map">

            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2799.1381538747!2d11.412907776273103!3d45.4468720710737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477f377e12131063%3A0xecf6fe80c7caa01c!2sMyLiteLed!5e0!3m2!1sit!2sit!4v1697620453157!5m2!1sit!2sit" 
                width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      
        </div>
    </div>

    <div class="page-content pb-0">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 mb-2 mb-lg-0">
                    <h2 class="title mb-1">{{"contactus.info" | translate}}</h2>
                    <!--p class="mb-3">{{"contactus.infoText" | translate}}</p-->

                    <div class="row">
                        <div class="col-sm-12">
                            <ul>
                                <li>
                                    <i class="icon-map-marker"></i>
                                    Via S. Bocconcello, 6 - 36040 Meledo di Sarego - VI
                                </li>
                                <li>
                                    <i class="icon-phone"></i>
                                    + 39 0444 82 12 61
                                </li>
                                <li>
                                    <i class="icon-envelope"></i>
                                    info@myliteled.com
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="contact-info">
                                <h3>Uffici</h3>

                                <ul class="contact-list">
                                    <li>
                                        <i class="icon-clock-o"></i>
                                        <span class="text-dark">Lunedì-venerdì<br>08.30-12.30/13.30-17.30</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="contact-info">
                                <h3>Magazzino</h3>

                                <ul class="contact-list">
                                    <li>
                                        <i class="icon-clock-o"></i>
                                        <span class="text-dark">Lunedì-venerdì<br>08.00-12.30/13.30-17.00</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <h2 class="title mb-1">{{"contactus.contactus" | translate}}</h2>
                    <!--p class="mb-3">{{"contactus.infoText" | translate}}</p-->

                    <form [formGroup]="contactForm" (submit)="contactSend()" class="contact-form mb-3">
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" class="form-control" formControlName="name"
                                    [placeholder]="'fld.name' | translate">
                                <div *ngIf="submitted && contactForm.get('name').hasError('required')" class="required">
                                    {{ "ctrl.name" | translate }}
                                </div>                                
                            </div>

                            <div class="col-sm-6">
                                <input type="text" class="form-control" formControlName="email"
                                    [placeholder]="'fld.email' | translate">
                                    <div *ngIf="submitted && ((contactForm.get('email').hasError('required')) || (contactForm.get('email').hasError('pattern') && !contactForm.get('email').hasError('required')))" class="required">
                                        {{ "ctrl.email" | translate }}
                                    </div>
                             </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" class="form-control" formControlName="phone"
                                    [placeholder]="'fld.phone' | translate">
                                <div *ngIf="submitted && contactForm.get('phone').hasError('required')" class="required">
                                    {{ "ctrl.phone" | translate }}
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <input type="text" class="form-control" formControlName="subject"
                                    [placeholder]="'fld.subject' | translate">
                                <div *ngIf="submitted && contactForm.get('subject').hasError('required')" class="required">
                                    {{ "ctrl.subject" | translate }}
                                </div>
                            </div>
                        </div>
                       
                        <textarea class="form-control" cols="30" rows="4" formControlName="msg"
                            [placeholder]="'fld.msg' | translate"></textarea>
                        <div *ngIf="submitted && contactForm.get('msg').hasError('required')" class="required">
                            {{ "ctrl.msg" | translate }}
                        </div>

                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input"
                                id="privacy1"
                                formControlName="privacy1"
                                [placeholder]="'privacy.dichiarazione' | translate"
                                (change)="contactForm.get('privacy1').updateValueAndValidity()">
                            <label class="custom-control-label" for="privacy1">
                                {{ "privacy.dichiarazione" | translate}}</label>
                        </div>
                        <div *ngIf="submitted && contactForm.get('privacy1').hasError('required')"
                            class="required">
                            {{ "ctrl.dichiarazione" | translate }}
                        </div>
                
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input"
                                id="privacy2"
                                formControlName="privacy2"
                                [placeholder]="'privacy.consenso' | translate"
                                (change)="contactForm.get('privacy2').updateValueAndValidity()">
                            <label class="custom-control-label" for="privacy2">
                                {{ "privacy.consenso" | translate}}</label>
                        </div>
                        <div *ngIf="submitted && contactForm.get('privacy2').hasError('required')"
                            class="required">
                            {{ "ctrl.consenso" | translate }}
                        </div>
                        
                        <button type="submit" class="btn btn-outline-primary-2 btn-minwidth-sm">
                            <span>{{ 'btn.submit' | translate }}</span>
                            <i class="icon-long-arrow-right"></i>
                        </button>
                    </form>
                </div>
            </div>

            <hr class="mt-4 mb-5">

            <div class="stores mb-4 mb-lg-5">
                <h2 class="title text-center mb-3">Dove siamo</h2>

                <div class="row text-center">
                    <div class="col-lg-12">
                        <div class="store">
                            <div class="row">
                                <div class="col-sm-5 col-xl-3">
                                    <figure class="store-media mb-2 mb-lg-0">
                                        <img src="assets/images/stores/myliteled-esterni-1.JPG" class="ml-auto mr-auto ml-sm-0"
                                            class="ml-auto mr-auto ml-sm-0" width="277" height="277" alt="image" />
                                    </figure>
                                </div>
                                <div class="col-sm-7 col-xl-3">
                                    <div class="store-content">
                                        <h3 class="store-title">Sede Legale</h3>
                                        <h4 class="store-subtitle">NOVA PUBBLICITÀ SRL Unipersonale</h4>

                                        Partita IVA  03217420243<br>
                                        Codice SDI  2LCMINU

                                        <address>Via S. Bocconcello, 32</address>
                                        <address>36040 Meledo di Sarego - VI</address>

                                        <div><a href="mailto:amministrazione@novapubblicita.com">amministrazione@novapubblicita.com</a></div>                                        

                                    </div>
                                </div>

                                <div class="col-sm-6 col-xl-3">
                                    <div class="store-content">
                                        <h3 class="store-title">Uffici</h3>
                                        
                                        <address>Via S. Bocconcello, 6</address>
                                        <address>36040 Meledo di Sarego - VI</address>
                                        <div><a href="mailto:info@myliteled.com">info@myliteled.com</a></div>

                                        <h4 class="store-subtitle">Orario:</h4>
                                        <div>Lun - Ven 08.30-12.30/13.30-17.30</div>

                                    </div>
                                </div>

                                <div class="col-sm-6 col-xl-3">
                                    <div class="store-content">
                                        <h3 class="store-title">Ufficio vendite</h3>
                                        
                                        <address>Via S. Bocconcello, 6</address>
                                        <address>36040 Meledo di Sarego - VI</address>
                                        <div><a href="mailto:vendite@myliteled.com">vendite@myliteled.com</a></div>

                                        <h4 class="store-subtitle">Orario:</h4>
                                        <div>Lun - Ven  08.30-12.30/13.30-17.30</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>