import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoginModel } from '../classes/LoginModel';
import { UserLoginModel } from '../classes/UserLoginModel';
import { WSResponseModel } from '../classes/WSResponseModel';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {
  baseUrl: string = environment.backend.baseURL;
  response!: WSResponseModel;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastrService: ToastrService,
    private translate: TranslateService) { }

  //Funzione di login che richiama il web service
  async authenticate(username: string, password: string, idAzienda: number): Promise<LoginModel> {

    const body: UserLoginModel = new UserLoginModel(username, password, idAzienda);

    //Viene richiesta l'autenticazione dell'utente con le credenziali insierite

    return await firstValueFrom(this.http.post<WSResponseModel>(`${environment.backend.baseURL}/Security/Authentication/Authenticate`, body))
      .then(
        resUser => {
          const response = JSON.parse(resUser.jsonlist);
          sessionStorage.setItem('currentUser', JSON.stringify(response));
          return response
        })
      .then((res) => {

        //se viene  ritornato l'utente, se ha un cliente associato ne vengono le condizioni economiche

        const httpOptions = {
          headers: new HttpHeaders({
            'idUtente': res.idUtente.toString()
          })
        };

        return firstValueFrom(this.http.get<WSResponseModel>(`${environment.backend.baseURL}/AnagraficheGet/CondCF`, httpOptions)).then(
          resCondCF => {
            const response = JSON.parse(resCondCF.jsonlist);
            sessionStorage.setItem('currentCondCF', JSON.stringify(response));

            this.toastrService.success(`${this.translate.instant('loginmodal.welcome')} ${res.username}`);

            return res //Dopo aver inizializzato le condizioni ritorno in ogni caso la loginModel
          }
        )
      })
      .catch(
        error => {
          console.log('error: ' + error.toString())
          if (error.status) {
            switch (error.status) {
              case 401: {
                this.router.navigate(['pages/login']);
                sessionStorage.clear();
                this.toastrService.error(error.error.title);
                break;
              }
              case 405: {
                //this.router.navigate(['home']);
                this.toastrService.error(error.error.title);
                break;
              }
              default: {
                if (error.error.info)
                  this.toastrService.error(error.error.info);
              }
            }

          } else {
            this.toastrService.error(this.translate.instant('loginmodal.ko'));
          }
        }
      );
  }



  //Funzione per verificare se l'utente è già abilitato
  verifytoken() {

    return this.http.get<WSResponseModel>(this.baseUrl + '/Security/Authentication/VerifyToken')
  }

  //Funzione per recuperare le funzioni utente
  async getFunctionUser() {

    return await firstValueFrom(this.http.get<WSResponseModel>(this.baseUrl + '/Security/Authentication/GetFunctionUser')).then(
      res => {
        return res;
      }
    ).catch(

      error => {
        if (error.status) {
          switch (error.status) {
            case 401: {
              this.router.navigate(['login']);
              sessionStorage.clear();
              //this.alertService.error(error.message);
              break;
            }
            case 405: {
              this.router.navigate(['home']);

              //this.alertService.error(error.message);
              break;
            }
            default: {
              if (error.error.info) {
                //this.alertService.error(error.error.info);
              } else {

                //this.alertService.error(error.message);
              }
            }
          }



        } else {
          this.response = error;
          //this.alertService.error(this.response.info);
        }

      }
    );

  }





}
