<main class="main">
	<nav class="breadcrumb-nav border-0 mb-0">
		<div class="container">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<a routerLink="/">Home</a>
				</li>
				<li class="breadcrumb-item">
					<a href="javascript:void();">Case study</a>
				</li>
				<li class="breadcrumb-item">
					<a href="javascript:void();">{{ item?.seo }}</a>
				</li>
			</ol>
		</div>
	</nav>

	<div class="page-content skeleton-body">
		<div *ngIf="!loaded else loadedBlock">
			<div class="skel-single-post"></div>
		</div>
		<ng-template #loadedBlock>
			<div class="container">
				<article class="entry single-entry entry-fullwidth">
					<div class="row">
						<div class="col-md-12 mb-3">
							<h2 class="title-xl">
								{{ item?.title }}
							</h2>
							<h2 class="title-lg title-light text-primary">
								{{ item?.subtitle }}
							</h2>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<h6 class="mb-0">Nome del cliente</h6>
							{{ item?.cliente }}
							<h6 class="mb-0 mt-3">Categoria</h6>
							{{ item?.categoria }}
							<h6 class="mb-0 mt-3">Data</h6>
							{{ item?.date }}
							<h6 class="mb-0 mt-3">Sfida</h6>
							{{ item?.sfida }}
							<h6 class="mb-0 mt-3">Risultati</h6>
							{{ item?.risultati }}
						</div>
						<div class="col-md-8">

							<figure class="entry-media entry-media-fullwidth"
								*ngIf="item?.pictures.length <= 1 else carouselBlock"
								[class.entry-video]="item?.type == 'video'" [style.padding-top]="'350px'">
								<a href="javascript:;" *ngIf="item?.type !== 'video' else videoBlock">
									<molla-image [src]="SERVER_URL + item?.pictures[0].url" [alt]="item?.title"
										fixedPt="350px"></molla-image>
								</a>
								<ng-template #videoBlock>
									<a href="javascript:;">
										<molla-image [src]="SERVER_URL + item?.pictures[0].url" [alt]="item?.title"
											fixedPt="350px">
										</molla-image>
									</a>
									<a [href]="item?.video" (click)="showModal($event,item?.video)"
										class="btn-video btn-iframe"><i class="icon-play"></i></a>
								</ng-template>
							</figure>

							<ng-template #carouselBlock>
								<figure class="entry-media entry-media-fullwidth" style="padding-top: 350px;"
									height="350px">
									<owl-carousel carouselClasses="owl-simple owl-light owl-nav-inside"
										[options]="sliderOption2">
										<a *ngFor="let picture of item?.pictures">
											<molla-image [src]="SERVER_URL + picture.url" [alt]="item?.title"
												fixedPt="350px">
											</molla-image>
										</a>
									</owl-carousel>
								</figure>
							</ng-template>

							<h3 class="title-light">{{ item?.highlight }}</h3>
							<p [innerHTML]="item?.content"></p>
							
							<a [href]="item?.attachment[0].url"
							class="btn btn-primary btn-md mt-3" target="_blank">
								<i class="icon-arrow-right"></i> Leggi la storia
							</a>
						</div>
					</div>

				</article>
			</div>
		</ng-template>

	</div>
</main>