<form [formGroup]="regForm" (submit)="register()">
    <input type="hidden" formControlName="idAzienda" value="1">
    <input type="hidden" formControlName="tipo" value="C">

    <div class="row">
        <div class="col-sm-6">
            <input type="text" class="form-control" formControlName="email"
                [placeholder]="'fld.email' | translate">
            <div *ngIf="submitted && ((regForm.get('email').hasError('required')) || (regForm.get('email').hasError('pattern') && !regForm.get('email').hasError('required')))" class="required">
                {{ "ctrl.email" | translate }}
            </div>
        </div>
        <div class="col-sm-6">
            <input type="password" class="form-control" formControlName="password"
                [placeholder]="'fld.password' | translate">
            <div *ngIf="submitted && regForm.get('password').hasError('required')"
                class="required">
                {{ "ctrl.password" | translate }}
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-6">
            <input type="text" class="form-control" formControlName="name"
                [placeholder]="'fld.name' | translate">
            <div *ngIf="submitted && regForm.get('name').hasError('required')"
                class="required">
                {{ "ctrl.name" | translate }}
            </div>
        </div>
        <div class="col-sm-6">
            <input type="text" class="form-control" formControlName="lastname"
                [placeholder]="'fld.lastname' | translate">
            <div *ngIf="submitted && regForm.get('lastname').hasError('required')"
                class="required">
                {{ "ctrl.lastname" | translate }}
            </div>
        </div>
    </div>

    <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input"
        id="privateCli"
        formControlName="privateCli"
        (change)="registerPrivate_changeEvent()">
        <label class="custom-control-label" for="privateCli">
            {{ "fld.private" | translate}}</label>
    </div>
    <div *ngIf="submitted && regForm.get('privateCli').hasError('required')"
        class="required">
        {{ "ctrl.private" | translate }}
    </div>

    <input type="text" class="form-control" formControlName="company"
        [placeholder]="'fld.company' | translate">
    <div *ngIf="submitted && regForm.get('company').hasError('required')" class="required">
        {{ "ctrl.company" | translate }}
    </div>

    <input type="text" class="form-control" formControlName="vat"
        [placeholder]="'fld.vat' | translate">
    <div *ngIf="submitted && regForm.get('vat').hasError('required')" class="required">
        {{ "ctrl.vat" | translate }}
    </div>

    <input type="text" class="form-control" formControlName="taxid"
        [placeholder]="'fld.taxid' | translate">
    <div *ngIf="submitted && regForm.get('taxid').hasError('required')" class="required">
        {{ "ctrl.taxid" | translate }}
    </div>

    <input type="text" class="form-control" formControlName="address"
        [placeholder]="'fld.address' | translate">
    <div *ngIf="submitted && regForm.get('address').hasError('required')" class="required">
        {{ "ctrl.address" | translate }}
    </div>


    <div class="row">
        <div class="col-sm-8">
            <input type="text" class="form-control" formControlName="town"
                [placeholder]="'fld.town' | translate">
            <div *ngIf="submitted && regForm.get('town').hasError('required')"
                class="required">
                {{ "ctrl.town" | translate }}
            </div>

        </div>
        <div class="col-sm-4">
            <input type="text" class="form-control" formControlName="pcode"
                [placeholder]="'fld.pcode' | translate">
            <div *ngIf="submitted && regForm.get('pcode').hasError('required')"
                class="required">
                {{ "ctrl.pcode" | translate }}
            </div>

        </div>
    </div>

    <div class="row">
        <div class="col-sm-6">
            <select class="form-control" formControlName="city"
                (change)="regCity_changeEvent()">
                <option value="">{{ "fld.city" | translate }}</option>
                <option *ngFor="let city of cities" [value]="city.PROV">{{city.DES_PROV}}</option>
            </select>
            <div *ngIf="submitted && regForm.get('city').hasError('required')"
                class="required">
                {{ "ctrl.city" | translate }}
            </div>
        </div>
        <div class="col-sm-6">
            <select class="form-control" formControlName="country" [attr.disabled]="true">
                <option value="IT" selected>Italia</option>
            </select>
            <div *ngIf="submitted && regForm.get('country').hasError('required')"
                class="required">
                {{ "ctrl.country" | translate }}
            </div>
        </div>
    </div>

    <input type="hidden" formControlName="state">

    <input type="text" class="form-control" formControlName="phone"
        [placeholder]="'fld.phone' | translate">
    <div *ngIf="submitted && regForm.get('phone').hasError('required')" class="required">
        {{ "ctrl.phone" | translate }}
    </div>

    <div class="form-footer">
        <button type="submit" class="btn btn-outline-primary-2 mb-2">
            <span>{{ "btn.continue" | translate | uppercase}}</span>
            <i class="icon-long-arrow-right"></i>
        </button>

        <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input"
                id="privacy1"
                formControlName="privacy1"
                [placeholder]="'privacy.dichiarazione' | translate"
                (change)="regForm.get('privacy1').updateValueAndValidity()">
            <label class="custom-control-label" for="privacy1">
                {{ "privacy.dichiarazione" | translate}}</label>
        </div>
        <div *ngIf="submitted && regForm.get('privacy1').hasError('required')"
            class="required">
            {{ "ctrl.dichiarazione" | translate }}
        </div>

        <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input"
                id="privacy2"
                formControlName="privacy2"
                [placeholder]="'privacy.consenso' | translate"
                (change)="regForm.get('privacy2').updateValueAndValidity()">
            <label class="custom-control-label" for="privacy2">
                {{ "privacy.consenso" | translate}}</label>
        </div>
        <div *ngIf="submitted && regForm.get('privacy2').hasError('required')"
            class="required">
            {{ "ctrl.consenso" | translate }}
        </div>
    </div>

</form>

<div class="form-choice d-none">
    <p class="text-center">or sign in with</p>
    <div class="row">
        <div class="col-sm-6">
            <a href="javascript:;" class="btn btn-login btn-g">
                <i class="icon-google"></i>
                Login With Google
            </a>
        </div>
        <div class="col-sm-6">
            <a href="javascript:;" class="btn btn-login  btn-f">
                <i class="icon-facebook-f"></i>
                Login With Facebook
            </a>
        </div>
    </div>
</div>