import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { customSay, team, brands } from './about-one-data';
import { sliderOpt } from 'src/app/shared/data';
import { JsonLDService } from 'src/app/shared/services/json-ld.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
	selector: 'pages-about-page',
	templateUrl: './about-one.component.html',
	styleUrls: ['./about-one.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AboutOneComponent implements OnInit {

	customSay = customSay;
	brands = brands;
	team = team;
	sliderOption = {
		...sliderOpt,
		nav: false,
		dots: true,
		margin: 20,
		loop: false,
		responsive: {
			1200: {
				nav: true
			}
		}
	}
	teamOption = {
		...sliderOpt,
		nav: false,
		dots: false,
		items: 3,
		margin: 20,
		loop: false,
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			992: {
				items: 3
			}
		}
	}

	constructor(
		public router: Router,
		private jsonLdService: JsonLDService,
		private title: Title,
		private meta: Meta) {
	}

	ngOnInit(): void {
		this.title.setTitle("About us")
		this.meta.updateTag({ name: 'description', content: "About us" })
		this.jsonLdService.insertSchema(JsonLDService.websiteSchema('', '', this.title.getTitle(), this.title.getTitle()));

	}
}
