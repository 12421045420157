<div class="container products electronics">
    <div class="heading heading-flex heading-border mb-3" contentAnim aniName="blurIn">
        <div class="heading-left">
            <h2 class="title">{{titolo}}</h2>
        </div>

        <div class="heading-right d-none">
            <ul class="nav nav-pills nav-border-anim justify-content-center" role="tablist" tabClick>
                <li class="nav-item" *ngFor="let attr of attrs; let index = index">
                    <a class="nav-link" [class.active]="index === 0" href="" role="tab"
                        (click)="nav.select( 'electronics-' + attr )">{{ titles[attr] }}</a>
                </li>
            </ul>
        </div>
    </div>

    <ul ngbNav #nav="ngbNav" class="tab-content tab-content-carousel" [destroyOnHide]="false">
        <li [ngbNavItem]="'electronics-' + attr" *ngFor="let attr of attrs; let index = index">
            <ng-template ngbNavContent>
                <owl-carousel carouselClasses="owl-simple carousel-equal-height carousel-with-shadow"
                    [options]="sliderOption" *ngIf="!loaded else loadedBlock">
                    <div class="skel-pro" *ngFor="let i of [1,2,3,4,5,6]"></div>
                </owl-carousel>
                <ng-template #loadedBlock>
                    <owl-carousel carouselClasses="owl-simple carousel-equal-height carousel-with-shadow"
                        [options]="sliderOption">

                        <molla-product-twelve
                        *ngFor="let product of products | attrFilter : attr | slice : 0: 6"
                        [product]="product">
                        </molla-product-twelve>
                        
                    </owl-carousel>
                </ng-template>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>