import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { JsonLDService } from 'src/app/shared/services/json-ld.service';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
	selector: 'molla-lightbox-revolution',
	templateUrl: './lightbox-revolution.component.html',
	styleUrls: ['./lightbox-revolution.component.scss']
})
export class LightboxRevolutionComponent implements OnInit {

	popovers = [
		{
		  top: '90%',
		  left: '87%',
		  isOpen: false,
		  imageSrc: '/assets/images/lightbox-revolution/lightbox-revolution-cambia%20animazione-5aff29ac.jpeg',
		  title: 'Cambia animazione quando vuoi',
		  content: 'In pochi secondi <strong>sostituisci l’animazione luminosa</strong>&nbsp;<strong>con una semplice USB</strong> collegata alla centralina sul retro del pannello.'
		},
		{
		  top: '60%',
		  left: '45%',
		  isOpen: false,
		  imageSrc: '/assets/images/lightbox-revolution/lightbox-revolution-telo_intercambiabile-19e148e6.jpeg',
		  title: 'Tessuto intercambiabile',
		  content: '<strong>Il tessuto stampato si sostituisce facilmente in pochi istanti</strong>. Cambia grafica tutte le volte che desideri!',
		},
		{
			top: '15%',
			left: '80%',
			isOpen: false,
			imageSrc: '',
			title: 'Animazione luminosa',
			content: 'Crea <strong>movimenti di luce coordinati con la grafica</strong> per mettere in risalto le parti più importanti della comunicazione.',
		  },
		  {
			top: '10%',
			left: '10%',
			isOpen: false,
			imageSrc: '',
			title: 'Cassonetto luminoso per tessuti',
			content: 'Cassonetto in alluminio con profilo per <strong>applicazione semplice e veloce di tessuti</strong>&nbsp;con gommino. <br>Dimensioni su misura (multipli di 32 cm) e spessore unico da 55 mm.',
		  },
	];

	constructor(
		public router: Router,
		private jsonLdService: JsonLDService,
		private title: Title,
		private meta: Meta,
		private modalService: ModalService) {
	}

	ngOnInit(): void {
		this.title.setTitle("Lightbox Revolution")
		this.meta.updateTag({ name: 'description', content: "Lightbox Revolution. Metti in movimento la tua comunicazione" })
		this.jsonLdService.insertSchema(JsonLDService.websiteSchema('', '', this.title.getTitle(), this.title.getTitle()));

	}

	showPopover(popover: any): void {
		this.popovers.forEach((p) => (p.isOpen = false));
		popover.isOpen = true;
	}

	hidePopover(): void {
		this.popovers.forEach((p) => (p.isOpen = false));
	}

	showModal(event: Event, url: string) {
		event.preventDefault();
		this.modalService.showVideoModal(url);
	}

}
