import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { map, catchError } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/shared/services/modal.service';
import { FormService } from 'src/app/shared/services/form.service';
import { ToastrService } from 'ngx-toastr';
import { JsonLDService } from 'src/app/shared/services/json-ld.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'pages-contact-one',
	templateUrl: './contact-one.component.html',
	styleUrls: ['./contact-one.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContactOnePageComponent implements OnInit {
	apiLoaded: Observable<boolean>;
	contactForm!: FormGroup;
	loading: boolean = false;
	submitted: boolean = false;
  
	constructor(
		private httpClient: HttpClient,
		private formBuilder: FormBuilder,
		private translate: TranslateService,
		private formService: FormService,
		private toastrService: ToastrService,
		private title: Title,
		private meta: Meta,
		private jsonLdService: JsonLDService) {
		this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyBzlLYISGjL_ovJwAehh6ydhB56fCCpPQw', 'callback')
		.pipe(
			map(() => true),
			catchError(() => of(false)),
		);
	}
	
	ngOnInit(): void {
		this.contactForm = this.formBuilder.group({
			name: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			phone: [''],
			subject: [''],
			msg: ['', Validators.required],
			privacy1: [null, Validators.requiredTrue],
			privacy2: [null, Validators.requiredTrue]
		});

		this.title.setTitle(this.translate.instant('contactus.contactus'))
		this.meta.updateTag({ name: 'description', content: 'LED,  insegne, cassonetti, lettere scatolate' })
		this.meta.updateTag({ name: 'keywords', content: 'Prodotti a LED per insegne, cassonetti, lettere scatolate.' })
		this.jsonLdService.insertSchema(JsonLDService.websiteSchema('Organization'));
	}

	async contactSend() {
		// Validazione dei campi del modulo
		if (!this.formService.validateForm(this.contactForm)) {
			this.submitted=true;
			return; // Esci se la validazione fallisce
		}		

		this.formService.sendFormContatto(this.contactForm).subscribe({

			next: data => {
				if (data.code === 0) {
					/*this.modalService.openSnackBar(this.translate.instant('msg.confReg'))*/
					this.toastrService.success(this.translate.instant('msg.confContct'))
				} else {
					//this.modalService.openAlertDialog(data!.info)
					this.toastrService.error(data!.info)
				}
					
			},
			error:  error => {
				if (error.status ) {
					//this.alertService.error(error.message);
					//this.modalService.openAlertDialog(error.message);
					this.toastrService.error(error.message)
				} else {          
					//this.alertService.error(error.info);
					//this.modalService.openAlertDialog(error.info);
					this.toastrService.error(error.info)
				}			
			}
		});
	}
}