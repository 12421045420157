import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

import { productSlider } from '../data';
import { truncateSync } from 'fs';
import { Product } from 'src/app/shared/classes/product.model';


@Component({
	selector: 'molla-products-collection',
	templateUrl: './products-collection.component.html',
	styleUrls: ['./products-collection.component.scss']
})
export class ProductsCollectionComponent implements OnInit {

	@Input()products: Product[];
	@Input()titolo = "";
	@Input()loaded = false;

	sliderOption = productSlider;
	//attrs = ['featured', 'new', 'top'];
	attrs = ['all', 'featured', '_new', 'top'];
	titles = { "all": "All", "featured": "In Evidenza", "_new": "New", "top": "Best Seller" };


	constructor() { }

	ngOnInit(): void {
		if (this.products) {
			this.loaded = true
		}
		
        //console.log('product : '+this.products[0].slug)
	}
}