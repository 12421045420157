<div class="modal-body">
    <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true"><i class="icon-close"></i></span>
    </button>

    <div class="form-box">
        <div class="form-tab nav-fill">
            <ul ngbNav #nav="ngbNav" class="justify-content-center nav nav-pills">
                <li ngbNavItem>
                    <a ngbNavLink>{{ "loginmodal.singin" | translate}}</a>
                    <ng-template ngbNavContent>
                        <molla-login-form [backpage]="'shop/dashboard'"></molla-login-form>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink>{{ "loginmodal.register" | translate}}</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="m_regForm" (submit)="register()">
                            <input type="text" class="form-control" formControlName="email"
                                [placeholder]="'fld.email' | translate">
                                <div *ngIf="submitted && ((m_regForm.get('email').hasError('required')) || (m_regForm.get('email').hasError('pattern') && !m_regForm.get('email').hasError('required')))" class="required">
                                    {{ "ctrl.email" | translate }}
                                </div>

                            <input type="password" class="form-control" formControlName="password"
                                [placeholder]="'fld.password' | translate">
                            <div *ngIf="submitted && m_regForm.get('password').hasError('required')" class="required">
                                {{ "ctrl.password" | translate }}
                            </div>

                            <div class="form-footer">
                                <button type="submit" class="btn btn-outline-primary-2 mb-2">
                                    <span>{{ "btn.continue" | translate | uppercase}}</span>
                                    <i class="icon-long-arrow-right"></i>
                                </button>

                                <div class="custom-control custom-checkbox d-none">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="privacy1"
                                        id="privacy1"
                                        [placeholder]="'privacy.dichiarazione' | translate"
                                        (change)="m_regForm.get('privacy1').updateValueAndValidity()">
                                    <label class="custom-control-label" for="privacy1">
                                        {{ "privacy.dichiarazione" | translate}}</label>
                                </div>
                                <div *ngIf="submitted && m_regForm.get('privacy1').hasError('required')" class="required">
                                    {{ "ctrl.dichiarazione" | translate }}
                                </div>

                                <div class="custom-control custom-checkbox d-none">
                                    <input type="checkbox" class="custom-control-input"
                                        formControlName="privacy2"
                                        id="privacy2"
                                        [placeholder]="'privacy.consenso' | translate"
                                        (change)="m_regForm.get('privacy2').updateValueAndValidity()">
                                    <label class="custom-control-label" for="privacy2">
                                       {{ "privacy.consenso" | translate}}</label>
                                </div>
                                <div *ngIf="submitted && m_regForm.get('privacy2').hasError('required')" class="required">
                                    {{ "ctrl.consenso" | translate }}
                                </div>
                            </div>

                        </form>
                        <div class="form-choice d-none">
                            <p class="text-center">or sign in with</p>
                            <div class="row">
                                <div class="col-sm-6">
                                    <a href="javascript:;" class="btn btn-login btn-g">
                                        <i class="icon-google"></i>
                                        Login With Google
                                    </a>
                                </div>
                                <div class="col-sm-6">
                                    <a href="javascript:;" class="btn btn-login  btn-f">
                                        <i class="icon-facebook-f"></i>
                                        Login With Facebook
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</div>