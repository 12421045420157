import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http'; // Importa HttpClient
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { sliderOpt } from 'src/app/shared/data';
import { environment } from 'src/environments/environment';
import { caseStudy } from 'src/app/shared/classes/case-study.model';
import { firstValueFrom } from 'rxjs';
import { select } from '@ngrx/store';
import { JsonLDService } from 'src/app/shared/services/json-ld.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'molla-case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.scss']
})
export class CaseStudyComponent implements OnInit {

  item: caseStudy;
  loaded = false;
  sliderOption2 = { ...sliderOpt, loop: false };
  SERVER_URL = environment.SERVER_URL;
  paddingTop = '100%';

  constructor(
    public activeRoute: ActivatedRoute,
		public router: Router,
    public utilsService: UtilsService,
    private modalService: ModalService,
    private http: HttpClient,
    private jsonLdService: JsonLDService,
		private title: Title,
		private meta: Meta
  ) {
    
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(params => {
      this.loaded = false;

      this.loadData(params['slug'])

      this.loaded = true;

      this.title.setTitle("Case study")
			this.meta.updateTag({ name: 'description',content: "Case study" })
      this.jsonLdService.insertSchema(JsonLDService.websiteSchema('', '', this.title.getTitle(), this.title.getTitle() ));
    
    });
  }

  async loadData(slug) {

    try {
      // Carica i dati JSON dal file utilizzando HttpClient
      const data: caseStudy[] = await firstValueFrom(this.http.get<caseStudy[]>('assets/data-files/caseStudy.json'));

      // Filtra i dati in base al parametro 'slug'
      this.item = data.find(item => item.slug === slug);

      // if (this.case) {
      //   this.paddingTop = Math.round((parseFloat(this.case.pictures[0].height) / parseFloat(this.case.pictures[0].width)) * 100) + '%';
      // }


    } catch (error) {
      console.log(error)
    }

  }

  trackByFn(index: number, item: any) {
    if (!item) return null;
    return item.slug;
  }

  showModal(event: Event, url:string) {
    event.preventDefault();
    this.modalService.showVideoModal(url);
  }
}