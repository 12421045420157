export class UserLoginModel{

    username!:string;
    password!:string;
    idAzienda!:number;

    constructor(user:string,pass:string,idazienda:number){

        this.username=user;
        this.password=pass;
        this.idAzienda=idazienda;

    }
}