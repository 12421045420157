import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

//Questo Service è un Interceptor che viene eseguito prima di ogni chiamata http al server
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const s = req.url.toLowerCase();
    //Recupero la cache ed eseguo l'inserimnto del toke nel header della chiamata
    var loginModel = JSON.parse(sessionStorage.getItem('currentUser')!);
    //Recupero l'url
    var url = this.router.url;
    //Eventuale controllo se ci sono chiamate per cui non deve essere eseguito questo interceptor  
    if (s.indexOf('https://calcolatore.myliteled.com') > -1) {
      return next.handle(req);
    } else {
      const headers = {
        ContentType: "application/json",
        IDDitta: environment.backend.IDDitta.toString()
      };

      if (loginModel && loginModel.token) {
        headers['token'] = loginModel.token;
      }

      const jsonReq = req.clone({
        setHeaders: headers
      })
      return next.handle(jsonReq as any);
    }
  }
}
