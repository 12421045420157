<div class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
        <div class="container">
            <ol class="breadcrumb" itemscope itemtype="https://schema.org/BreadcrumbList">
                <li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                    <a routerLink="/" itemprop="item">Home</a>
                    <meta itemprop="position" content="1" />
                </li>
                <li class="breadcrumb-item active" itemprop="itemListElement" itemscope
                    itemtype="https://schema.org/ListItem">
                    <a itemprop="item">MyLiteLED</a>
                    <meta itemprop="position" content="2" />
                </li>
            </ol>
        </div>
    </nav>

    <div class="container">
        <div class="page-header page-header-big text-center"
            style="background-image: url('assets/images/banner-chi-siamo.jpg')">
            <h1 class="page-title text-white">Vivere sotto una nuova luce</h1>
        </div>
    </div>

    <div class="page-content pb-0">
        <div class="container">
            <div class="row">
                <div class="col-md-5 mb-3 mb-lg-0">
                    <h2 class="title dropcap">
                        Siamo ciò che facciamo</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5 mb-3 mb-lg-0">
                    <img src="/assets/images/about/foto-piero-dal-cavaliere-ebeae4c7.jpeg" alt="CEO" />
                    <h2 class="title-sm mt-2">
                        Pierino Dal Cavaliere<br>
                        <span class="lead text-muted">Founder & CEO</span>
                    </h2>
                </div>

                <div class="col-md-7">
                    <p>
                        Immagina un <b>gruppo di esperti in prodotti per l’illuminazione a LED</b>, unito a trent’anni
                        di esperienza nel campo della comunicazione luminosa.
                        Immagina un’<b>azienda che dal 2009 seleziona e importa dal mercato internazionale i LED con il
                            miglior rapporto tra innovazione, qualità e prezzo</b>.
                        Immagina un laboratorio in cui la luce viene osservata, studiata e messa alla prova.
                        Tutto questo è MyLiteLed.
                    </p>
                    <p>
                        Il mondo dell’illuminazione evolve quotidianamente, offrendo possibilità costruttive e di design
                        inedite, quindi ogni progetto necessita di LED con caratteristiche di luminosità, durata e
                        affidabilità differenti.
                        Ogni applicazione deve essere opportunamente valutata per costi e benefici.
                        <b>La nostra struttura, proprio perchè specializzata e non legata agli interessi dei singoli
                            marchi, rappresenta un partner prezioso per rivenditrori, installatori, insegnisti,
                            architetti, light designer e arredatori</b> alla ricerca del prodotto ideale per realizzare
                        la loro idea di luce.
                    </p>
                </div>
            </div>

            <div class="mb-5"></div>
        </div>

        <div class="mb-2"></div>

        <div class="bg-light-2 pt-6 pb-6">
            <div class="container text-center">
                <h2 class="title mb-3">
                    Scarica la nostra presentazione aziendale
                </h2>
                <a href="/assets/download/MLL-brochure-aziendale-22-web.pdf"
                class="btn btn-primary btn-md" target="_blank">
                    <i class="icon-arrow-down"></i> Presentazione
                </a>
            </div>
        </div>

        <div class="mb-5"></div>

        <div class="container">
            <div class="row">
                <div class="col-md-5 mb-3 mb-lg-0">
                    <h2 class="title dropcap">
                        In cosa crediamo</h2>
                </div>

                <div class="col-md-7">
                    <p>
                        Crediamo nel <b>potere dei piccoli gesti</b>.
                        Proponiamo sistemi di illuminazione a basso impatto energetico, investiamo in soluzioni che permettono uno <b>sviluppo sostenibile</b>, per vivere davvero sotto una nuova luce.
                        Crediamo in uno stile di vita amico dell’ambiente e lo dimostriamo impegnandoci ogni giorno nella diffusione di un <b>atteggiamento responsabile e rispettoso delle generazioni future</b>.
                        Crediamo nell’importanza del cliente e dei suoi desideri. Per questo cerchiamo di realizzare ciò che ci chiede, di rassicurarlo se ha dubbi, di suggerirgli soluzioni.
                        <b>Crediamo nelle promesse e nei patti</b>.
                        Crediamo che il lavoro, come la vita, sia un viaggio verso il miglioramento;
                        un viaggio in cui non è importante la meta, ma il cammino e gli amici.
                    </p>
                </div>
            </div>

            <div class="mb-5"></div>
        </div>

        <div class="mb-2"></div>

        <div class="bg-light-2 pt-6 pb-6">
            <div class="container">
                <div class="row">
                    <div class="col-md-5 mb-3 mb-lg-0">
                        <h2 class="title dropcap">
                            Possiamo aiutarti?
                        </h2>
                        <h3 class="title-sm">
                            Chiedi informazioni e consigli ai nostri esperti.
                            <br>Sapranno indicarti la soluzione migliore.
                        </h3>
                    </div>
    
                    <div class="col-md-7" style="font-size:1.7em;">
                        <ul>
                            <li>
                                <i class="icon-phone"></i>
                                + 39 0444 82 12 61
                            </li>
                            <li>
                                <i class="icon-envelope"></i>
                                info@myliteled.com
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>

        <div class="pt-6 pb-6 banner-about banner-container">
            <div class="container text-center">
                <h2 class="title text-white mb-3">
                    Seguici su Linkedin
                </h2>
                <p class="text-white">Diventa follower della nostra pagina aziendale Linkedin. Rimani in contatto con noi e segui gli aggiornamenti.</p>
                <a href="https://it.linkedin.com/company/myliteled-srl" class="btn btn-outline-white-2 btn-md mt-2" target="_blank">
                    Visita la nostra pagina
                </a>
            </div>
        </div>        
    </div>
</div>