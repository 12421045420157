import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

@Injectable({
	providedIn: 'root'
})
export class JsonLDService {
	static scriptType = 'application/ld+json';
	static websiteSchema = (tipo?: string, url?: string, name?: string, description?: string, params?: any) => {

		const dataSchema = {
			'@context': 'http://schema.org',
			'@type': tipo,
			url: url || window.location.href, // Use the provided 'url' or fallback to the current window location
			name: name || 'MyLiteLED',
		};
	
		if (description) {
			dataSchema['description'] = description;
		}
	
		switch (tipo) {
			case 'Organization': {
				dataSchema['contactPoint'] = {
					'@type': 'ContactPoint',
					telephone: '+39-0444-821261',
					contactType: 'customer support'
				};
				break;
			}
			case 'Product': {
				for (let key in params) {
					switch (key) {
						case 'image':
							dataSchema['image'] = [params[key]];
							break;
						case 'aggregateRating':
							dataSchema['aggregateRating'] = {
								"@type": "AggregateRating",
								ratingValue: params[key],
								reviewCount: params[key]
							};
							break;
						default:
							dataSchema[key] = params[key];
					}
				}
				break;
			}
			default: {
				for (let key in params) {
					dataSchema[key] = params[key];
				}
				break;
			}
		}
	
		return dataSchema;
	};
	

	constructor(@Inject(DOCUMENT) private _document: Document) { }

	removeStructuredData(): void {
		const els: Element[] = [];
		['structured-data', 'structured-data-org'].forEach(c => {
			els.push(...Array.from(this._document.head.getElementsByClassName(c)));
		});
		els.forEach(el => this._document.head.removeChild(el));
	}

	insertSchema(schema: Record<string, any>, className = 'structured-data'): void {

		let script: any;
		let shouldAppend = false;
		if (this._document.head.getElementsByClassName(className).length) {
			script = this._document.head.getElementsByClassName(className)[0];
		} else {
			script = this._document.createElement('script');
			shouldAppend = true;
		}
		script.setAttribute('class', className);
		script.type = JsonLDService.scriptType;
		script.text = JSON.stringify(schema);
		if (shouldAppend) {
			this._document.head.appendChild(script);
		}
	}
}