import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { cataloghiProdotti } from 'src/app/shared/classes/cataloghi-prodotti.model';
import { JsonLDService } from 'src/app/shared/services/json-ld.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'molla-cataloghi-prodotti',
  templateUrl: './cataloghi-prodotti.component.html',
  styleUrls: ['./cataloghi-prodotti.component.scss']
})
export class CataloghiProdottiComponent implements OnInit {
  data: cataloghiProdotti[]
  loaded = false;
  SERVER_URL = environment.SERVER_URL;
  paddingTop = '100%';

  constructor(
    public activeRoute: ActivatedRoute,
    private http: HttpClient,
    private translate: TranslateService,
		private title: Title,
		private meta: Meta,
		private jsonLdService: JsonLDService
  ) {
    
  }

  ngOnInit(): void {
      this.loaded = false;

      this.loadData()

      this.title.setTitle(this.translate.instant('cataloghi.titolo2'))
      this.meta.updateTag({ name: 'description', content: 'LED,  insegne, cassonetti, lettere scatolate' })
      this.meta.updateTag({ name: 'keywords', content: 'Prodotti a LED per insegne, cassonetti, lettere scatolate.' })
      this.jsonLdService.insertSchema(JsonLDService.websiteSchema('Organization'));

      this.loaded = true;
  }

  async loadData() {

    try {
      // Carica i dati JSON dal file utilizzando HttpClient
      this.data = await firstValueFrom(this.http.get<cataloghiProdotti[]>('assets/data-files/cataloghiProdotti.json'));

    } catch (error) {
      console.log(error)
    }

  }
}