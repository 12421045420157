import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { LoginModel } from 'src/app/shared/classes/LoginModel';
import { LoginModalComponent } from 'src/app/shared/components/modals/login-modal/login-modal.component';


@Component({
	selector: 'pages-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})

export class LoginPageComponent implements OnInit, AfterViewInit {
	@ViewChild('nav') nav: NgbNav; // Riferimento a ngbNav
	loginModel!: LoginModel;

	constructor(
		private router: Router) {
	}


	ngOnInit(): void {
		this.loginModel = JSON.parse(sessionStorage.getItem('currentUser')!);

		if (this.loginModel && this.loginModel.token) {
			this.router.navigateByUrl('/shop/dashboard');
		}
	}

	ngAfterViewInit(): void {
		const currentUrl = this.router.url;
		// Confronta l'URL con le tue rotte
		if (currentUrl.includes('/pages/login')) {
			this.nav.select('login');
		} else if (currentUrl.includes('/pages/register')) {
			this.nav.select('registration');
		} else if (currentUrl.includes('/pages/edit-account-address')) {
			this.nav.select('registration');
		}

	}
}