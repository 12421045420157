import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AnaService } from 'src/app/shared/services/ana.service';
import { FormService } from 'src/app/shared/services/form.service';


@Component({
  selector: 'molla-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {

  pwForm!: FormGroup;
	loading: boolean = false;
	submitted: boolean = false;

  constructor(
    private anaService: AnaService,
    private formBuilder: FormBuilder,
		private translate: TranslateService,
		private formService: FormService,
		private toastrService: ToastrService,
		private router: Router,
  ) { }

  get f() { return this.pwForm.controls; }

  ngOnInit(): void {

    //Carico i controlli della form
		this.pwForm = this.formBuilder.group({
			pwold: ['', Validators.required],
      pwnew: ['', Validators.required],
      pwconfirm: ['', Validators.required],
    });

  }


  async pwChange(){
    this.submitted = true;
		if (this.pwForm.invalid) {
			return;
		}
		this.loading = true;


    const pwnew = this.f['pwnew'].value;
    const pwconfirm = this.f['pwconfirm'].value;

    // Check if pwnew and pwconfirm match
    if (pwnew !== pwconfirm) {
      this.toastrService.error("Le password non coincidono.")
      this.submitted = true;
			return; // Esci se la validazione fallisce
		}	

    // Validazione dei campi del modulo
		if (!this.formService.validateForm(this.pwForm)) {
			this.submitted = true;
			return; // Esci se la validazione fallisce
		}		

		let res = await this.anaService.ChangePassword(this.f['pwold'].value, this.f['pwnew'].value,)

		if (res && res.code === 0) {
      this.toastrService.success(this.translate.instant('msg.changePwOk'))
      this.router.navigateByUrl('/shop/dashboard');
    } else {
      this.toastrService.error((res && res.info) ? res.info : this.translate.instant('msg.changePwKo'))
    }
  }
}
