import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WSResponseModel } from '../classes/WSResponseModel';
import { environment } from 'src/environments/environment';
import { Observable, firstValueFrom, tap } from 'rxjs';
import { AnaUser } from '../classes/ana-user.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticateService } from './authenticate.service';
import * as sha from 'js-sha256';
import { AnaCFList } from '../classes/ana-cf-list.model';

@Injectable({
  providedIn: 'root'
})
export class AnaService {

  constructor(
    private http: HttpClient,
    private authenticateService: AuthenticateService,
    private translate: TranslateService,
    private toastrService: ToastrService
  ) { }


  async sendFormRegistrazione(form: AnaUser, newUser: boolean = true) {
    const httpOptions = {
      headers: new HttpHeaders({
        'EmailMailFrom': environment.backend.EmailFrom,
        'EmailMailTo': environment.backend.EmailTo,
        'newUser': newUser.toString()
      }),
      rejectUnauthorized: false,
    };


    return await firstValueFrom(this.http.post<WSResponseModel>(
      `${environment.backend.baseURL}/EC/AnagrafichePost/sendFormRegistrazione`,
      form,
      httpOptions
    )).then((data) => {
      let loginModal:any;
      if (data.code === 0) {
        this.toastrService.success(this.translate.instant('msg.confReg'))

        //const regEmailValue = form.userid ? form.userid : null;
        //const regPasswordValue = form.password ? form.password : null;

        //loginModal = this.authenticateService.authenticate(regEmailValue, sha.sha256(regPasswordValue), 1);

      } else {

        this.toastrService.error(data!.info)
      }

      return loginModal

    }).catch(
      error => {
        console.log('error: ' + error.toString())
        if (error.status) {
          switch (error.status) {
            case 400: {
              this.toastrService.error(error.error.info);
              break;
            }            
            default: {
              this.toastrService.error(error.message);
            }
          }
        } else {
          this.toastrService.error(error.message);
        }
      }
    );
  }


  //recupera i dati dell'utente da User
  async fetchDatiUtente(idUtente: number) {
    const httpOptions = {
      headers: {
        idUtente: idUtente.toString()
      }
    };

    const endpoint = 'AnagraficheGet/WebUserData';
    const url = `${environment.backend.baseURL}/${endpoint}`;

    return await firstValueFrom(this.http.get<WSResponseModel>(url, httpOptions))
    .then( result => {
      return JSON.parse(result!.jsonlist)
    })
    .catch(
      error => {
        console.log('error: ' + error.toString())
        if (error.status) {
          switch (error.status) {
            case 400: {
              this.toastrService.error(error.error.info);
              break;
            }            
            default: {
              this.toastrService.error(error.message);
            }
          }
        } else {
          this.toastrService.error(error.message);
        }
      }
    );
  }

  //recupera i dati del Cliente Web da PD_WEB_ANA_USERS
  async fetchDatiCF(COD_CF: string) {
    const httpOptions = {
      headers: {
        'COD_CF': COD_CF
      }
    };

    const endpoint = 'AnagraficheGet/CFWebUserData';
    const url = `${environment.backend.baseURL}/${endpoint}`;

    return await firstValueFrom(this.http.get<WSResponseModel>(url, httpOptions))
    .then( result => {
      return JSON.parse(result!.jsonlist)
    })
    .catch(
      error => {
        console.log('error: ' + error.toString())
        if (error.status) {
          switch (error.status) {
            case 400: {
              this.toastrService.error(error.error.info);
              break;
            }            
            default: {
              this.toastrService.error(error.message);
            }
          }
        } else {
          this.toastrService.error(error.message);
        }
      }
    );
  }
    
  //Verifica l'esistenza della P.Iva nei Clienti Web di PD_WEB_ANA_USERS
  async CheckVatUser(vat: string, initial = `EC/AnagrafichePost/checkVatUser`) {
    let temp = initial;
		if (!initial.includes('?')) {
			temp += '?';
		}
    if (vat) {
			temp += 'vat=' + vat;
		}

    return await firstValueFrom(this.http.post<WSResponseModel>(`${environment.backend.baseURL}/=${temp}`,``))
    .then( result => {
      return result!.code;
    }).catch(
      error => {
        console.log('error: ' + error.toString())
        if (error.status) {
          switch (error.status) {
            case 400: {
              this.toastrService.error(error.error.info);
              break;
            }            
            default: {
              this.toastrService.error(error.message);
            }
          }
        } else {
          this.toastrService.error(error.message);
        }
      }
    );
  }

  //recupera le condizioni economiche del Cliente associato all'utente 
  async GetCondCF(idUtente: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'idUtente': idUtente.toString()
      })
    };

    return await firstValueFrom(this.http.get<WSResponseModel>(`${environment.backend.baseURL}/AnagraficheGet/CondCF`, httpOptions)).then(
      res => {
        const response = JSON.parse(res.jsonlist);
        sessionStorage.setItem('currentCondCF', JSON.stringify(response));
        return response
      }
    )
    .then( result => {
      return JSON.parse(result!.jsonlist)
    })
    .catch(
      error => {
        if (error.status) {
          switch (error.status) {
            case 401: {

              //this.router.navigate(['login']);
              sessionStorage.clear();
              this.toastrService.error(error.message);
              break;
            }
            case 405: {
              //this.router.navigate(['home']);

              this.toastrService.error(error.message);
              break;
            }
            default: {
              if (error.error.info)
                //this.toastrService.error(this.response.info);

                this.toastrService.error(error.message);
            }
          }

        } else {
          //this.response = error.error;
          //this.toastrService.error(this.response.info);
        }

      }
    );

  }
  
  //recupera la password degli utenti registrati come Clienti in PD_WEB_ANA_USERS
  async ResetPassword(username: string, initial = `EC/AnagrafichePost/ResetPassword`) {
    let temp = initial;
		if (!initial.includes('?')) {
			temp += '?';
		}
    if (username) {
			temp += 'username=' + username;
		}

    const httpOptions = {
      headers: new HttpHeaders({
        'EmailMailFrom': environment.backend.EmailFrom,
      }),
      rejectUnauthorized: false,
    };


    return await firstValueFrom(this.http.post<WSResponseModel>(`${environment.backend.baseURL}/${temp}`, null,
      httpOptions
    ))
    .then( result => {
      return result;
    })
    .catch(
      error => {
        console.log('error: ' + error.toString())
        if (error.status) {
          switch (error.status) {
            case 400: {
              this.toastrService.error(error.error.info);
              break;
            }            
            default: {
              this.toastrService.error(error.message);
            }
          }
        } else {
          this.toastrService.error(error.message);
        }
      }
    );
  }



  
  //recupera la password degli utenti registrati come Clienti in PD_WEB_ANA_USERS
  async ChangePassword(OldPassword: string, NewPassword: string, initial = `Configuration/ChangePassword`) {

    let temp = initial;
		if (!initial.includes('?')) {
			temp += '?';
		}

    var body = { oldPassword: OldPassword, newPassword: NewPassword }

    return await firstValueFrom(this.http.post<WSResponseModel>(
      `${environment.backend.baseURL}/${temp}`,
      body
    ))
    .then( result => {
      return result;
    })
    .catch(
      error => {
        console.log('error: ' + error.toString())
        if (error.status) {
          switch (error.status) {
            case 400: {
              this.toastrService.error(error.error.info);
              break;
            }            
            default: {
              this.toastrService.error(error.message);
            }
          }
        } else {
          this.toastrService.error(error.message);
        }
      }
    );
  }

  
  
  //recupera la password degli utenti registrati come Clienti in PD_WEB_ANA_USERS
  async GetCodAgente(idUtente: number, initial = `AnagraficheGet/CodAgente`) {

    let temp = initial;
		if (!initial.includes('?')) {
			temp += '?';
		}
    if (idUtente) {
			temp += 'idUtente=' + idUtente;
		}

    return await firstValueFrom(this.http.get<WSResponseModel>(
      `${environment.backend.baseURL}/${temp}`
    ))
    .then( result => {
      return  JSON.parse(result!.jsonlist);
    })
    .catch(
      error => {
        console.log('error: ' + error.toString())
        if (error.status) {
          switch (error.status) {
            case 400: {
              this.toastrService.error(error.error.info);
              break;
            }            
            default: {
              this.toastrService.error(error.message);
            }
          }
        } else {
          this.toastrService.error(error.message);
        }
      }
    );
  }

  
  async fetchCliList
    (
		COD_AGE1_CLI: string,
		COD_AGE2_CLI: string,
		COD_CF: string = '',
		RAG_SOC_CF: string = '',
    P_IVA_CF: string = '',
		COMUNE_CF: string = '',
		): Promise<AnaCFList> {
		
		const httpOptions = {
			headers: {
				COD_AGE1_CLI: COD_AGE1_CLI,
				COD_AGE2_CLI: COD_AGE2_CLI
			}
		};

		let endpoint = `EC/AnagrafichePost/ListCli?`;


		var body = { 
			COD_CF: COD_CF, 
			RAG_SOC_CF: RAG_SOC_CF,     
			P_IVA_CF: P_IVA_CF, 
			COMUNE_CF: COMUNE_CF,
      FLAG_CLI: 1}

		const url = `${environment.backend.baseURL}/${endpoint}`;

		try {
			const response = await firstValueFrom(this.http.post<WSResponseModel>(url, body, httpOptions));
			const parsedResponse = JSON.parse(response!.jsonlist);
			return parsedResponse;
		} catch (error) {
			//console.log('error: ' + error.toString());
			if (error.status) {
				switch (error.status) {
					case 400: {
						this.toastrService.error(error.error.info);
						break;
					}
					default: {
						this.toastrService.error(error.message);
					}
				}
			} else {
				this.toastrService.error(error.message);
			}
			throw error; // Rilancia l'errore per la gestione successiva
		}
	}
}